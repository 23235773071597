import axios from 'axios';
import { DELETE_LOT } from '../../constants';

export const deleteLot = (lID, setDeleteDialog, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadDeleteLot());
  axios
    .delete(`/lots/deleteLot?lotId=${lID}`, config)
    .then((res) => {
      dispatch(setDeleteLot(res));
      setDeleteDialog(false);
    })
    .catch((err) => {
      dispatch(errorDeleteLot(err.response));
      onError(err.response);
    });
};

export const loadDeleteLot = () => ({
  type: DELETE_LOT.LOAD,
});

export const setDeleteLot = (data) => ({
  type: DELETE_LOT.SUCCESS,
  payload: data,
});

export const errorDeleteLot = (error) => ({
  type: DELETE_LOT.FAIL,
  payload: error,
});
