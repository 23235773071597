import axios from 'axios';
import { LOT_LIST } from '../../constants';

export const fetchLotList = (onError, monthly) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadLotList());

  axios
    .get(
      monthly ? '/lots/getLots?showMonthlyData=true' : '/lots/getLots',
      config
    )
    .then((res) => dispatch(setLotList(res)))
    .catch((err) => {
      dispatch(errorLotList(err.response));
      onError(err.response);
    });
};

export const loadLotList = () => ({
  type: LOT_LIST.LOAD,
});

export const setLotList = (userList) => ({
  type: LOT_LIST.SUCCESS,
  payload: userList.data.data,
});

export const errorLotList = (error) => ({
  type: LOT_LIST.FAIL,
  payload: error,
});
