import React from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';
import searchIcon from '../assets/images/icon/searchIcon.svg';

const SearchBar = (props) => {
  // const [searchShow, setSearchShow] = useState(false)
  // const onSearch = () => {
  //     setSearchShow(!searchShow)
  // }
  return (
    <React.Fragment>
      <div className='d-none d-md-block'>
        <InputGroup className='searchBar'>
          <InputGroup.Text id='search-icon' className='searchCommon'>
            <img src={searchIcon} alt='search' />
          </InputGroup.Text>
          <FormControl
            type='text'
            className='searchCommon'
            placeholder='Type to search'
            onChange={props.onChange}
            value={props.value}
          />
        </InputGroup>
      </div>
      {/* <Button className='btn_search d-md-none' onClick={onSearch}/>
            {searchShow ? 
                <div className='w-100 mt13 border-bottom'>
                    <Form.Control type="search" name="search" placeholder='Type to search' />
                </div> : null
            } */}
    </React.Fragment>
  );
};

export default SearchBar;
