import jwtDecode from 'jwt-decode';
import { FILE_SIZE } from './constants';

export const getErrorMessage = (error) =>
  error && error.data && error.data.message;
export const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
// export const bodyOverflow = (overflow) => {
//   document.getElementsByTagName('BODY')[0].style.overflow = overflow;
// };
export const digitsRegex = /^[0-9]+$/g;

export const userType = () => {
  return localStorage.jwtToken && jwtDecode(localStorage.jwtToken).userType;
};

export const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-+_!@#$%^&*.,?]).{8,}/;

export const getFileName = (fileName) => {
  return fileName.substr(0, fileName.lastIndexOf('.'));
};

export const getFileExtention = (fileName) => {
  return fileName.substr(fileName.lastIndexOf('.') + 1);
};

export const getFileSize = (fileSize) => {
  let _size = fileSize;
  let i = 0;
  while (_size > 900) {
    _size /= 1024;
    i++;
  }
  return Math.round(_size * 100) / 100 + ' ' + FILE_SIZE[i];
};
