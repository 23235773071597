import { Avatar, Box } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import calenderIcon from '../../assets/images/icon/calenderIcon.svg';
import messageIcon from '../../assets/images/icon/messageIcon.svg';
import { Strings } from '../../utils/Strings';
import CommonButton from '../Button';
// import GroupImages from '../GroupImages';

import deleteIcon from '../../assets/images/icon/blackDeleteIcon.svg';
import editIcon from '../../assets/images/icon/blackEditIcon.svg';
import { userType } from '../../utils/appUtils';

const JobsProgressData = (props) => {
  const {
    title,
    jobsArray,
    dotColor,
    openDeleteDialog,
    fetchJobId,
    setOpenEditDialog,
  } = props;
  return (
    <React.Fragment>
      <div className='jobs_dataBox common_datacard'>
        <div className='w-100 d-flex justify-content-between align-items-center'>
          <span className='font22 font_light'>{title}</span>
          <div className='d-flex align-items-center'>
            <div className={`dots ${dotColor}`} />
            <span className='font16 font700 font_secondary3'>
              {jobsArray && jobsArray?.length}
            </span>
          </div>
        </div>
        {jobsArray?.length === 0 ? (
          <Box className='spinner-container'>
            <p className='no-data-message'>{Strings.JOB_NOTFOUND}</p>
          </Box>
        ) : (
          <Row className='flex-wrap'>
            {jobsArray &&
              jobsArray.map((data, index) => {
                return (
                  <Col sm={12} className='mt-4' key={index}>
                    <div className='job_dataCard common_datacard'>
                      {/* ======= Title & Images ======= */}
                      <div className='d-flex align-items-center justify-content-between'>
                        <span className='font22 font_light mb-2 mb-md-0'>
                          {data.title}
                        </span>
                        <Avatar sx={{ bgcolor: '#2c659b' }}>
                          {data.assignedTo[0]?.name[0]?.toUpperCase()}
                        </Avatar>
                        {/* <GroupImages images={data.assignedTo} /> */}
                      </div>
                      {/* ======= AML & Priority ======= */}
                      <div className='d-flex align-items-center mt13'>
                        <div className='btn_job aml_box font12 font600'>
                          {data.lots[0]?.title}
                        </div>
                        {/* <div className='btn_job priority_box font12 font600'>{data.prior}</div> */}
                      </div>
                      {/* ======== Date & Message ======= */}
                      <div className='d-flex flex-column flex-md-row align-items-md-center justify-content-md-between mt13'>
                        <div className='d-flex align-items-center mb-2 mb-md-0'>
                          <img src={calenderIcon} alt='not found' />
                          <span className='font_light ms-2'>
                            {moment(data?.dueDate)?.format('DD MMM, YYYY')}
                          </span>
                        </div>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex align-items-center'>
                            <img src={messageIcon} style={{ cursor:'pointer' }} alt='not found' onClick={() => {
                                  setOpenEditDialog(true);
                                  fetchJobId(data._id);
                                }} />
                            <span className='font_light ms-2'>
                              {data.comments && data.comments.length}
                            </span>
                          </div>
                          {userType() !== 'client' ? (
                            <div className='d-flex align-items-center ms-3'>
                              <CommonButton
                                icon={deleteIcon}
                                noMarginTop
                                className='btn_dataTable d-flex align-items-center'
                                onClick={() => {
                                  openDeleteDialog(true);
                                  fetchJobId(data?._id);
                                }}
                              />
                              <CommonButton
                                icon={editIcon}
                                noMarginTop
                                className='btn_dataTable ms-2 d-flex align-items-center'
                                onClick={() => {
                                  setOpenEditDialog(true);
                                  fetchJobId(data?._id);
                                }}
                              />
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })}
          </Row>
        )}
      </div>
    </React.Fragment>
  );
};

export default JobsProgressData;
