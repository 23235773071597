import React from 'react';
import {
  Avatar,
  Stack,
  // TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import CommonButton from '../Button';
// import CommonCheckbox from '../Checkbox';
import calenderIcon from '../../assets/images/icon/calenderIcon.svg';
import messageIcon from '../../assets/images/icon/messageIcon.svg';
import deleteIcon from '../../assets/images/icon/deleteIcon.svg';
import editIcon from '../../assets/images/icon/editIcon.svg';
import PropTypes from 'prop-types';
import moment from 'moment';
import { userType } from '../../utils/appUtils';
// import GroupImages from '../GroupImages';

const EnhancedTableBody = (props) => {
  const {
    order,
    orderBy,
    stableSort,
    isSelected,
    handleClick,
    getComparator,
    arrayData,
    openDeleteDialog,
    fetchJobId,
    setOpenEditDialog,
  } = props;

  return (
    <>
      {stableSort(arrayData, getComparator(order, orderBy)).map(
        (data, index) => {
          const isItemSelected = isSelected(data.title);
          return (
            <TableRow
              hover
              role='checkbox'
              aria-checked={isItemSelected}
              tabIndex={-1}
              key={data._id}
              sx={{ width: '100%' }}
              className='coll_row'
            >
              {/* <TableCell padding='none'>
                <CommonCheckbox
                  onChange={(event) => handleClick(event, data.title)}
                  checked={isItemSelected}
                  selected={isItemSelected}
                  className='table_checkbox'
                />
              </TableCell> */}
              <TableCell component='td' scope='row'>
                <Box>
                  <Stack direction='column' alignItems='flex-start'>
                    <Typography className='font22 font_light'>
                      {data?.title}
                    </Typography>
                    <Typography className='font_secondary3 font_light font16'>
                      Created on {moment(data.createdAt).format('DD MMM, YYYY')}
                    </Typography>
                  </Stack>
                </Box>
              </TableCell>
              <TableCell className='font_light font16'>
                <span className='btn_job d-inline-block aml_box font12 font600'>
                  {data.lots[0]?.title}
                </span>
              </TableCell>
              <TableCell className='font_light font16'>
                <img
                  alt='job'
                  src={calenderIcon}
                  style={{ marginRight: '6px' }}
                />
                {moment(data.dueDate).format('DD MMM, YYYY')}
              </TableCell>
              <TableCell className='font_light font16'>
                <Avatar sx={{ bgcolor: '#2c659b' }}>
                  {data.assignedTo[0].name[0].toUpperCase()}
                </Avatar>
                {/* <GroupImages images={data.assignedTo} /> */}
              </TableCell>
              <TableCell className='font_light font16'>
                <img
                  alt='job'
                  src={messageIcon}
                  style={{ marginRight: '6px', cursor: 'pointer' }}
                  onClick={() => {
                    setOpenEditDialog();
                    fetchJobId(data._id);
                  }}
                />
                {data.comments && data.comments.length}
              </TableCell>
              {userType() !== 'client' ? (
                <TableCell align='right' className='font_light font16'>
                  <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='end'
                  >
                    <CommonButton
                      noMarginTop
                      className='btn_dataTable'
                      icon={deleteIcon}
                      onClick={() => {
                        openDeleteDialog(true);
                        fetchJobId(data._id);
                      }}
                    />
                    <CommonButton
                      noMarginTop
                      className='btn_dataTable ms-3'
                      icon={editIcon}
                      onClick={() => {
                        setOpenEditDialog();
                        fetchJobId(data._id);
                      }}
                    />
                  </Stack>
                </TableCell>
              ) : null}
            </TableRow>
          );
        }
      )}
    </>
  );
};

EnhancedTableBody.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  stableSort: PropTypes.func.isRequired,
  isSelected: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  getComparator: PropTypes.func.isRequired,
  arrayData: PropTypes.array.isRequired,
};

export default EnhancedTableBody;
