import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import CommonButton from '../components/Button';
import Textbox from '../components/Textbox';
import logo from '../assets/images/logo1.png';
import { connect } from 'react-redux';
import { forgotPassword } from '../actions/authActions/forgotPassword';
import { emailRegex, getErrorMessage } from '../utils/appUtils';
import { useSnackbar } from 'notistack';
import { Strings } from '../utils/Strings';

const ResetPassword = (props) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error',
      });
    }
  };

  const onSuccess = (data) => {
    setEmail('');
    enqueueSnackbar(data, {
      variant: 'success',
    });
  };

  const onForgotPassword = () => {
    if (!email) setError(Strings.EMAIL_REQUIRED);
    else if (email && !emailRegex.test(email))
      setError(Strings.VALID_EMAIL);
    else {
      props.forgotPassword(
        { email },
        (err) => onError(err),
        (data) => onSuccess(data)
      );
    }
  };
  return (
    <React.Fragment>
      <div className='form_alignments d-flex flex-column align-items-center m-0'>
        {/* ===== Header Section ===== */}
        <div className='logo'>
          <img src={logo} className='w-100' alt='not found' />
        </div>
        <span className='form_title font_light'>{Strings.TEXT_RESET_PASSWORD}</span>
        <span className='form_subtitle font_light text-center'>
          {Strings.TEXT_ENTER_PASSWORD_DETAILS}
        </span>

        {/* ====== Form ====== */}
        <Form className='w-100 main_form'>
          <Textbox
            label='Email *'
            placeholder='Enter your email'
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setError('');
            }}
            error={error}
          />
          <CommonButton
            noMarginTop
            text='SUBMIT'
            onClick={onForgotPassword}
            loading={props.forgotPasswordDataLoading}
            disabled={props.forgotPasswordDataLoading}
          />
        </Form>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  forgotPasswordData: state.forgotPasswordData.data,
  forgotPasswordDataLoading: state.forgotPasswordData.loading,
});

export default connect(mapStateToProps, {
  forgotPassword,
})(ResetPassword);
