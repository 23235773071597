import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/images/logo1.png';
import CommonButton from '../components/Button';
import Textbox from '../components/Textbox';
import CommonCheckbox from '../components/Checkbox';
import LinkButton from '../components/LinkButton';
import { connect } from 'react-redux';
import { login } from '../actions/authActions/login';
import { emailRegex, getErrorMessage } from '../utils/appUtils';
import { useSnackbar } from 'notistack';
import { Strings } from '../utils/Strings';

const Login = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState({ email: '', password: '' });
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    const rememberEmail = localStorage.getItem('email');
    const rememberPassword = localStorage.getItem('password');
    const rememberMe = localStorage.getItem('rememberMe');
    if (rememberEmail && rememberPassword && rememberMe) {
      setEmail(rememberEmail);
      setPassword(rememberPassword);
      setRememberMe(rememberMe);
    }
  }, []);

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error',
      });
    }
  };

  const onLogin = () => {
    if (!email && !password)
      setError({
        email: Strings.EMAIL_REQUIRED,
        password: Strings.PASSWORD_REQUIRED,
      });
    else if (!email)
      setError({ email: Strings.EMAIL_REQUIRED, password: '' });
    else if (!password)
      setError({ email: '', password: Strings.PASSWORD_REQUIRED });
    else if (email && !emailRegex.test(email)) {
      setError({ email: Strings.VALID_EMAIL, password: '' });
    } else {
      props.login(
        {
          email,
          password,
        },
        navigate,
        (err) => onError(err),
        rememberMe
      );
    }
  };
  return (
    <React.Fragment>
      <div className='form_alignments d-flex flex-column align-items-center m-0'>
        {/* ===== Header Section ===== */}
        <div className='logo'>
          <img src={logo} className='w-100' alt='not found' />
        </div>
        <span className='form_title font_light'>{Strings.TEXT_WELCOME}</span>
        <span className='form_subtitle font_light'>
          {Strings.TEXT_ENTER_DETAILS}
        </span>

        {/* ====== Form ====== */}
        <Form className='w-100 main_form'>
          <Textbox
            label='Email *'
            name='email'
            placeholder='Enter your email'
            onChange={(e) => {
              setEmail(e.target.value);
              setError({ email: '', password: '' });
            }}
            error={error.email}
            value={email}
          />
          <Textbox
            label='Password *'
            name='password'
            type='password'
            placeholder='Enter your password'
            onChange={(e) => {
              setPassword(e.target.value);
              setError({ email: '', password: '' });
            }}
            error={error.password}
            value={password}
          />

          <div className='w-100 d-flex align-items-center justify-content-between'>
            <CommonCheckbox
              label={Strings.TEXT_REMEMBER}
              onChange={(e) => setRememberMe(e.target.checked)}
              checked={rememberMe}
            />
            <LinkButton to='/resetpassword' text={Strings.TEXT_FORGET_PASSWORD} />
          </div>
          <CommonButton
            text='SIGN IN'
            onClick={onLogin}
            loading={props.loginDataLoading}
            disabled={props.loginDataLoading}
          />
        </Form>

        {/* <div className='d-flex flex-column align-items-center'>
          <span className='font_light form_subtitle'>
            {Strings.TEXT_DONT_ACCOUNT  }
          </span>
          <LinkButton to='/' text={Strings.TEXT_CONTACT_SUPPORT} />
        </div> */}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  loginData: state.loginData.data,
  loginDataLoading: state.loginData.loading,
});

export default connect(mapStateToProps, {
  login,
})(Login);
