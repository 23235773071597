import React, { useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { Strings } from '../../utils/Strings';
import JobsDataCard from '../jobsData/JobsDataCard';

const JobsData = (props) => {
    const { data, loading } = props;
    const [key, setKey] = useState('toDo');

    return (
        <React.Fragment>
            <div className='w-100 mt24'>
                <div className='w-100 d-flex flex-column justify-content-center'>
                    <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
                        <Tab eventKey="toDo" title={Strings.TO_DO_TITLE.toUpperCase()}>
                            <JobsDataCard jobsArray={data.toDo} loading={loading} />
                        </Tab>
                        <Tab eventKey="progress" title={Strings.IN_PROGRESS_TITLE.toUpperCase()}>
                            <JobsDataCard jobsArray={data.inProgress} loading={loading} />
                        </Tab>
                        <Tab eventKey="complete" title={Strings.COMPLETE_TITLE.toUpperCase()}>
                            <JobsDataCard jobsArray={data.complete} loading={loading} />
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </React.Fragment>
    )
}

export default JobsData