import axios from 'axios';
import { SEARCH_JOB } from '../../constants';

export const searchJob = (word, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadSearchJob());

  axios
    .get(`jobs/searchJobs?word=${word}`, config)
    .then((res) => dispatch(setSearchJob(res)))
    .catch((err) => {
      dispatch(errorSearchJob(err.response));
      onError(err.response);
    });
};

export const loadSearchJob = () => ({
  type: SEARCH_JOB.LOAD,
});

export const setSearchJob = (job) => ({
  type: SEARCH_JOB.SUCCESS,
  payload: job.data.data,
});

export const errorSearchJob = (error) => ({
  type: SEARCH_JOB.FAIL,
  payload: error,
});
