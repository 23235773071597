import React, { useState, useEffect, Fragment } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import JobsProgressData from '../components/jobsData/JobsProgressData';
import kanbanIcon from '../assets/images/icon/kanbanIcon.svg';
import listIcon from '../assets/images/icon/listIcon.svg';
import caretWhite from '../assets/images/icon/caretWhite.svg';
import caretBlack from '../assets/images/icon/caretBlack.svg';
// Material Ui
import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
// Table Component
import EnhancedTableHead from '../components/Datatable/TableHead';
// import EnhancedTableToolbar from '../components/Datatable/TableToolbar';
import EnhancedTableBody from '../components/Datatable/TableBody';
import { connect } from 'react-redux';
// import { fetchJobList } from '../actions/jobActions/jobList';
import { getErrorMessage } from '../utils/appUtils';
import { useSnackbar } from 'notistack';
import CommonSpinner from '../components/CommonSpinner';
import Dialog from '../components/Dialog';
import { deleteJob } from '../actions/jobActions/deleteJob';
import { Strings } from '../utils/Strings';
import AddJobs from './AddJobs';
import { searchJob } from '../actions/jobActions/searchJobs';

// ===== Create Tabs =====
const TabNavItem = ({
  id,
  title,
  image,
  activeTab,
  setActiveTab,
  setDropOpen,
}) => {
  const handleClick = () => {
    setActiveTab(id);
    setDropOpen && setDropOpen(true);
  };
  return (
    <li
      onClick={handleClick}
      className={
        activeTab === id
          ? 'active_tab font_light font16 jobs_tab d-flex align-items-center'
          : 'font_light font16 jobs_tab d-flex align-items-center'
      }
    >
      <img src={image} alt={title} style={{ marginRight: '10px' }} />
      {title}
    </li>
  );
};

const TabContent = ({ id, activeTab, children }) => {
  return activeTab === id ? <div className='TabContent'>{children}</div> : null;
};

// ======== Table Head =========
const headCells = [
  { id: 'title', label: 'Title' },
  { id: 'lot', label: 'Lot' },
  { id: 'dueDate', label: 'Due to' },
  { id: 'assignedUser', label: 'Assigned to' },
];

const JobsPage = (props) => {
  // this for active link
  const [activeTab, setActiveTab] = useState('kanban');

  // This for toggle list
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('title');
  const [selected, setSelected] = useState([]);
  const [jobListData, setJobListData] = useState({});
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [jobID, setJobID] = useState('');

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    props.searchJob(props.searchData, (err) => onError(err));
  }, [
    props.searchData,
    props.deleteJobData,
    props.addJobData,
    props.updateJobData,
  ]);

  // useEffect(() => {
  //   props.fetchJobList((err) => onError(err));
  // }, [props.deleteJobData, props.addJobData, props.updateJobData]);

  useEffect(() => {
    const data = props.searchJobData ? props.searchJobData : {};
    setJobListData(data);
  }, [props.searchJobData]);

  // === Delete Job ===
  const onDeleteJob = () => {
    props.deleteJob(jobID, setDeleteDialog, (err) => onError(err));
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelecteds = jobsData.map((n) => n.title);
  //     setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  // };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <Fragment>
      <div
        className='w-100'
        style={{
          height: 'calc(100vh - 80px)',
          overflow: 'auto',
          padding: '0px 24px',
          paddingBottom: 10,
        }}
      >
        {props.searchJobLoading ? (
          <Box className='spinner-container'>
            <CommonSpinner color='#2c659b' />
          </Box>
        ) : (
          <>
            {deleteDialog && (
              <Dialog
                title={Strings.JOB_TITLE}
                message={Strings.DELETE_JOB_MESSAGE}
                onSuccess={onDeleteJob}
                close={() => setDeleteDialog(false)}
                loading={props.deleteJobDataLoading}
              />
            )}
            {/* ===== Tabs ===== */}
            <div className='mt-3 mb-3'>
              <ul className='nav'>
                <TabNavItem
                  title='Kanban'
                  id='kanban'
                  image={kanbanIcon}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
                <TabNavItem
                  title='List'
                  id='list'
                  image={listIcon}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  setDropOpen={setOpen}
                />
              </ul>
            </div>

            {/* ===== Kanban View ===== */}
            <TabContent id='kanban' activeTab={activeTab}>
              <Row className='mt-3'>
                <Col lg={4}>
                  <JobsProgressData
                    dotColor='bg_sec3'
                    title={Strings.TO_DO_TITLE}
                    jobsArray={jobListData.toDo}
                    openDeleteDialog={(data) => setDeleteDialog(data)}
                    fetchJobId={(data) => setJobID(data)}
                    setOpenEditDialog={() => setOpenEditDialog(true)}
                  />
                </Col>
                <Col lg={4}>
                  <JobsProgressData
                    dotColor='bg_prim1'
                    title={Strings.IN_PROGRESS_TITLE}
                    jobsArray={jobListData.inProgress}
                    openDeleteDialog={(data) => setDeleteDialog(data)}
                    fetchJobId={(data) => setJobID(data)}
                    setOpenEditDialog={() => setOpenEditDialog(true)}
                  />
                </Col>
                <Col lg={4}>
                  <JobsProgressData
                    dotColor='bg_green'
                    title={Strings.COMPLETE_TITLE}
                    jobsArray={jobListData.complete}
                    openDeleteDialog={(data) => setDeleteDialog(data)}
                    fetchJobId={(data) => setJobID(data)}
                    setOpenEditDialog={() => setOpenEditDialog(true)}
                  />
                </Col>
              </Row>
            </TabContent>

            {/* ===== List View ===== */}
            <TabContent id='list' activeTab={activeTab}>
              {/* ==================== */}
              <Box
                sx={{
                  width: '100%',
                }}
                className='dataTable_box'
              >
                <Paper sx={{ width: '100%', mb: 2 }} className='dataTable_box'>
                  {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
                  <TableContainer className='dataTable'>
                    <Table sx={{ minWidth: 800 }} aria-labelledby='tableTitle'>
                      <EnhancedTableHead
                        headCells={headCells}
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        // onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={
                          jobListData?.toDo?.length +
                          jobListData?.inProgress?.length +
                          jobListData?.complete?.length
                        }
                      />
                      <TableBody>
                        {/* ===== table to-do dropDown ===== */}
                        <TableRow>
                          <TableCell
                            colSpan={6}
                            className='todo_drop table_commonDrop'
                          >
                            <Stack
                              direction='row'
                              justifyContent='space-between'
                              alignItems='center'
                            >
                              <Stack direction='row' alignItems='center'>
                                <Typography className='font_light font700'>
                                  {Strings.TO_DO_TITLE}
                                </Typography>
                                <Typography className='font_secondary3 font700 ms-3'>
                                  {jobListData &&
                                    jobListData.toDo &&
                                    jobListData.toDo.length}
                                </Typography>
                              </Stack>
                              <Button
                                className='btn_tableDrop'
                                onClick={() => {
                                  setOpen(!open);
                                }}
                              >
                                <img
                                  src={caretBlack}
                                  alt='down'
                                  className={open ? 'dropdown_arrow' : ''}
                                />
                              </Button>
                            </Stack>
                          </TableCell>
                        </TableRow>
                        {open ? (
                          jobListData &&
                          jobListData.toDo &&
                          jobListData.toDo.length === 0 ? (
                            <TableRow
                              sx={{
                                backgroundColor:
                                  'rgba(177, 173, 162, 0.4) !important',
                              }}
                            >
                              <TableCell colSpan={6}>
                                <p className='no-data-message text-center mb-0'>
                                  {Strings.JOB_NOTFOUND}
                                </p>
                              </TableCell>
                            </TableRow>
                          ) : (
                            <EnhancedTableBody
                              order={order}
                              orderBy={orderBy}
                              selected={selected}
                              stableSort={stableSort}
                              isSelected={isSelected}
                              handleClick={handleClick}
                              getComparator={getComparator}
                              arrayData={jobListData && jobListData.toDo}
                              openDeleteDialog={(data) => setDeleteDialog(data)}
                              fetchJobId={(data) => setJobID(data)}
                              setOpenEditDialog={() => setOpenEditDialog(true)}
                            />
                          )
                        ) : null}

                        {/* ===== table progress dropDown ===== */}
                        <TableRow>
                          <TableCell
                            colSpan={6}
                            className='progress_drop table_commonDrop'
                          >
                            <Stack
                              direction='row'
                              justifyContent='space-between'
                              alignItems='center'
                            >
                              <Stack direction='row' alignItems='center'>
                                <Typography className='font_white font700'>
                                  {Strings.IN_PROGRESS_TITLE}
                                </Typography>
                                <Typography className='font_white font700 ms-3'>
                                  {jobListData &&
                                    jobListData.inProgress &&
                                    jobListData.inProgress.length}
                                </Typography>
                              </Stack>
                              <Button
                                className='btn_tableDrop'
                                onClick={() => {
                                  setOpen1(!open1);
                                }}
                              >
                                <img
                                  src={caretWhite}
                                  alt='down'
                                  className={open1 ? 'dropdown_arrow' : ''}
                                />
                              </Button>
                            </Stack>
                          </TableCell>
                        </TableRow>
                        {open1 ? (
                          jobListData &&
                          jobListData.inProgress &&
                          jobListData.inProgress.length === 0 ? (
                            <TableRow
                              sx={{
                                backgroundColor:
                                  'rgba(177, 173, 162, 0.4) !important',
                              }}
                            >
                              <TableCell colSpan={6}>
                                <p className='no-data-message text-center mb-0'>
                                  {Strings.JOB_NOTFOUND}
                                </p>
                              </TableCell>
                            </TableRow>
                          ) : (
                            <EnhancedTableBody
                              order={order}
                              orderBy={orderBy}
                              selected={selected}
                              stableSort={stableSort}
                              isSelected={isSelected}
                              handleClick={handleClick}
                              getComparator={getComparator}
                              arrayData={jobListData && jobListData.inProgress}
                              openDeleteDialog={(data) => setDeleteDialog(data)}
                              fetchJobId={(data) => setJobID(data)}
                              setOpenEditDialog={() => setOpenEditDialog(true)}
                            />
                          )
                        ) : null}

                        {/* ===== table complete dropDown ===== */}
                        <TableRow>
                          <TableCell
                            colSpan={6}
                            className='complete_drop table_commonDrop'
                          >
                            <Stack
                              direction='row'
                              justifyContent='space-between'
                              alignItems='center'
                            >
                              <Stack direction='row' alignItems='center'>
                                <Typography className='font_white font700'>
                                  {Strings.COMPLETE_TITLE}
                                </Typography>
                                <Typography className='font_white font700 ms-3'>
                                  {jobListData &&
                                    jobListData.complete &&
                                    jobListData.complete.length}
                                </Typography>
                              </Stack>
                              <Button
                                className='btn_tableDrop'
                                onClick={() => {
                                  setOpen2(!open2);
                                }}
                              >
                                <img
                                  src={caretWhite}
                                  alt='down'
                                  className={open2 ? 'dropdown_arrow' : ''}
                                />
                              </Button>
                            </Stack>
                          </TableCell>
                        </TableRow>
                        {open2 ? (
                          jobListData &&
                          jobListData.complete &&
                          jobListData.complete.length === 0 ? (
                            <TableRow
                              sx={{
                                backgroundColor:
                                  'rgba(177, 173, 162, 0.4) !important',
                              }}
                            >
                              <TableCell colSpan={6}>
                                <p className='no-data-message text-center mb-0'>
                                  {Strings.JOB_NOTFOUND}
                                </p>
                              </TableCell>
                            </TableRow>
                          ) : (
                            <EnhancedTableBody
                              order={order}
                              orderBy={orderBy}
                              selected={selected}
                              stableSort={stableSort}
                              isSelected={isSelected}
                              handleClick={handleClick}
                              getComparator={getComparator}
                              arrayData={jobListData && jobListData.complete}
                              openDeleteDialog={(data) => setDeleteDialog(data)}
                              fetchJobId={(data) => setJobID(data)}
                              setOpenEditDialog={() => setOpenEditDialog(true)}
                            />
                          )
                        ) : null}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Box>
            </TabContent>
          </>
        )}
      </div>
      {openEditDialog && (
        <AddJobs
          title='Edit job'
          jobId={jobID}
          onClose={() => {
            setOpenEditDialog(false);
          }}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  // jobListData: state.jobListData.data,
  // jobListLoading: state.jobListData.loading,

  searchJobData: state.searchJobData.data,
  searchJobLoading: state.searchJobData.loading,

  deleteJobData: state.deleteJobData.data,
  deleteJobDataLoading: state.deleteJobData.loading,
  addJobData: state.addJobData.data,
  updateJobData: state.updateJobData.data,

  searchData: state.searchData.search,
});

export default connect(mapStateToProps, {
  // fetchJobList,
  searchJob,
  deleteJob,
})(JobsPage);
