import axios from 'axios';
import { DELETE_JOB_FILE } from '../../constants';

export const deleteJobFile = (data, onSuccess, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadDeleteJobFile());
  axios
    .post('/jobs/deleteJobFile', data, config)
    .then((res) => {
      onSuccess(res);
      dispatch(setDeleteJobFile(res));
    })
    .catch((err) => {
      dispatch(errorDeleteJobFile(err.response));
      onError(err);
    });
};

export const loadDeleteJobFile = () => ({
  type: DELETE_JOB_FILE.LOAD,
});

export const setDeleteJobFile = (data) => ({
  type: DELETE_JOB_FILE.SUCCESS,
  payload: data,
});

export const errorDeleteJobFile = (error) => ({
  type: DELETE_JOB_FILE.FAIL,
  payload: error,
});
