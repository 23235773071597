import React from 'react';
import { Form } from 'react-bootstrap';

export default function Dropdown(props) {
  const { label, title, onChange, value, optionArray, noLabel } = props;
  return (
    <Form.Group>
      {noLabel ? null : (
        <Form.Label htmlFor={label} className={`font16 font_light form_label`}>
          {label}
        </Form.Label>
      )}
      <Form.Select
        value={value}
        onChange={onChange}
        className={`${
          !props.error && !props.noBottomMargin && 'mb16'
        } form_dropdown ${props.className}`}
      >
        {title && (
          <option value={0} disabled>
            {title}
          </option>
        )}
        {optionArray &&
          optionArray.map((item) => (
            <option key={item.value} value={item.value}>
              {item.title}
            </option>
          ))}
      </Form.Select>
    </Form.Group>
  );
}
