import { combineReducers } from 'redux';
import login from './authReducer/login';
import forgotPassword from './authReducer/forgotPassword';
import addUser from './superAdminReducer/addUser';
import listUser from './superAdminReducer/userList';
import updateUser from './superAdminReducer/updateUser';
import deleteUser from './superAdminReducer/deleteUser';
import listLot from './lotReducer/lotList';
import deleteLot from './lotReducer/deleteLot';
import listClient from './lotReducer/clientList';
import uploadFile from './superAdminReducer/uploadFile';
import addLot from './lotReducer/addLot';
import getLot from './lotReducer/getLot';
import updateLot from './lotReducer/updateLot';
import deleteLotFile from './lotReducer/deleteLotFile';
import getMonthlyProgress from './superAdminReducer/getMonthlyProgress';
import listJob from './jobReducer/jobList';
import resetPassword from './authReducer/resetPassword';
import getClientMonthlyProgress from './clientReducer/getClientMonthlyProgress';
import deleteJobReducer from './jobReducer/deleteJob';
import getLotFileReducer from './lotReducer/getLotFile';
import deleteJobFileReducer from './jobReducer/deleteJobFile';
import addJob from './jobReducer/addJob';
import getJob from './jobReducer/getJob';
import updateJob from './jobReducer/updateJob';
import addComment from './commentReducer/addComment';
import deleteComment from './commentReducer/deleteComment';
import getComment from './commentReducer/getComment';
import searchLots from './lotReducer/searchLots';
import setSearch from './commonReducer/setSearch';
import searchJobs from './jobReducer/searchJobs';

export default combineReducers({
  loginData: login,
  forgotPasswordData: forgotPassword,
  addUserData: addUser,
  userListData: listUser,
  updateUserData: updateUser,
  deleteUserData: deleteUser,

  lotListData: listLot,
  deleteLotData: deleteLot,

  clientListData: listClient,
  uploadFileData: uploadFile,
  addLotData: addLot,
  getLotData: getLot,
  searchLotData: searchLots,
  getLotFileData: getLotFileReducer,
  updateLotData: updateLot,
  deleteLotFileData: deleteLotFile,

  getMonthlyProgressData: getMonthlyProgress,
  resetPasswordData: resetPassword,

  jobListData: listJob,
  deleteJobData: deleteJobReducer,
  deleteJobFileData: deleteJobFileReducer,

  getClientMonthlyProgressData: getClientMonthlyProgress,

  addJobData: addJob,
  searchJobData: searchJobs,
  getJobData: getJob,
  updateJobData: updateJob,

  addCommentData: addComment,
  deleteCommentData: deleteComment,
  getCommentData: getComment,

  searchData: setSearch,
});
