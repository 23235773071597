import axios from 'axios';
import { RESET_PASSWORD } from '../../constants';
import setAuthToken from '../../utils/setAuthToken';
import { setLogin } from './login';

export const resetPassword = (data, onError, onSuccess) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadResetPassword());
  axios
    .post('user/changePassword', data, config)
    .then((res) => {
      onSuccess(res.data.data);
      localStorage.clear();
      setAuthToken(false);
      dispatch(setLogin({}));
      window.location.replace('/login');
      dispatch(setResetPassword(res.data.data));
    })
    .catch((err) => {
      dispatch(errorResetPassword(err.response));
      onError(err.response);
    });
};

export const loadResetPassword = () => ({
  type: RESET_PASSWORD.LOAD,
});

export const setResetPassword = (data) => ({
  type: RESET_PASSWORD.SUCCESS,
  payload: data,
});

export const errorResetPassword = (error) => ({
  type: RESET_PASSWORD.FAIL,
  payload: error,
});
