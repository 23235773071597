import React from 'react';
import { Link } from 'react-router-dom';

export default function LinkButton(props) {
  return (
    <Link to={props.to} className={`txt_link ${props.className}`}>
      {props.text}
    </Link>
  );
}
