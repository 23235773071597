import jwtDecode from 'jwt-decode';
import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import AuthLayout from './layouts/AuthLayout';
import MainLayout from './layouts/MainLayout';
import Dashboard from './pages/Dashboard';
import JobsPage from './pages/JobsPage';
import Login from './pages/LoginPage';
import LotsPage from './pages/LotsPage';
import ResetPassword from './pages/ResetPasswordPage';
import UsersPage from './pages/UsersPage';
import setAuthToken from './utils/setAuthToken';
import store from './store';
import { logout, setLogin } from './actions/authActions/login';
import { userType } from './utils/appUtils';
import NotFound from './pages/NotFound';

if (localStorage.jwtToken) {
  const token = localStorage.jwtToken;
  setAuthToken(token);
  const decoded = jwtDecode(token);
  store.dispatch(setLogin(decoded));
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logout());
    window.location.href = '/login';
  }
}

const Routes = () => {
  return useRoutes([
    localStorage.jwtToken
      ? {
          path: '/',
          element: <MainLayout />,
          children:
            userType() === 'client'
              ? [
                  { path: '/', element: <Navigate to='/app/jobs' /> },
                  { path: '/app', element: <Navigate to='/app/jobs' /> },
                  { path: '/app/jobs', element: <JobsPage />, text: 'Jobs' },
                ]
              : [
                  { path: '/', element: <Navigate to='/app/dashboard' /> },
                  { path: '/app', element: <Navigate to='/app/dashboard' /> },
                  {
                    path: '/app/dashboard',
                    element: <Dashboard />,
                    text: 'Dashboard',
                  },
                  { path: '/app/users', element: <UsersPage />, text: 'Users' },
                  { path: '/app/lots', element: <LotsPage />, text: 'Lots' },
                  { path: '/app/jobs', element: <JobsPage />, text: 'Jobs' },
                ],
        }
      : {
          path: '/',
          element: <AuthLayout />,
          children: [
            { path: '/', element: <Navigate to='login' /> },
            { path: 'login', element: <Login /> },
            { path: 'resetpassword', element: <ResetPassword /> },
          ],
        },
      {path: '*', element: <Navigate to='/404' />},
      {path: '/404', element: <NotFound />}
  ]);
};

export default Routes;
