import axios from 'axios';
import { JOB_LIST } from '../../constants';

export const fetchJobList = (onError, monthly) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadJobList());

  axios
    .get(
      monthly ? '/jobs/getJobs?showMonthlyData=true' : '/jobs/getJobs',
      config
    )
    .then((res) => dispatch(setJobList(res)))
    .catch((err) => {
      dispatch(errorJobList(err.response));
      onError(err.response);
    });
};

export const loadJobList = () => ({
  type: JOB_LIST.LOAD,
});

export const setJobList = (jobList) => ({
  type: JOB_LIST.SUCCESS,
  payload: jobList.data.data,
});

export const errorJobList = (error) => ({
  type: JOB_LIST.FAIL,
  payload: error,
});
