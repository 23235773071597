import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import CommonSpinner from '../CommonSpinner';

// const percentage = 60; // progress percentage
const progressColor = 'rgba(44, 101, 155, 1)';
const completeColor = 'rgba(98, 217, 160, 1)';

const activeColor = 'rgba(251, 184, 18, 1)';
const inActiveColor = 'rgba(187, 187, 188, 1)';

const JobsProgress = (props) => {
  const { loading, data, title } = props;
  const inProgress =
    (title !== 'Users' &&
      data &&
      data[0] &&
      data[0].inProgress &&
      Math.round(data[0].inProgress)) ||
    0;
  const complete =
    (title !== 'Users' &&
      data &&
      data[0] &&
      data[0].complete &&
      Math.round(data[0].complete)) ||
    0;

  const active =
    (title === 'Users' &&
      data &&
      data[0] &&
      data[0].active &&
      Math.round(data[0].active)) ||
    0;
  const inActive =
    (title === 'Users' &&
      data &&
      data[0] &&
      data[0].inActive &&
      Math.round(data[0].inActive)) ||
    0;

  const total = (data && data[0] && data[0].total && data[0].total) || 0;
  return (
    <div className='progress_card'>
      <div className='w-100'>
        {title && <span className='font28 font_light card_title'>{title}</span>}
        {loading ? (
          <div className='progress_chart d-flex justify-content-center align-items-center'>
            <CommonSpinner color='#58585a' />
          </div>
        ) : (
          <div className='progress_chart d-flex justify-content-between align-items-center'>
            {/*  =========== Texts ===========  */}
            <div className='d-flex flex-column'>
              <div className='d-flex align-items-center mb10'>
                <div
                  className='dots'
                  style={{
                    backgroundColor: `${
                      title === 'Users' ? activeColor : progressColor
                    }`,
                  }}
                ></div>
                <span className='font_light font_info'>
                  {title === 'Users'
                    ? `${active}% Active`
                    : `${inProgress}% in progress`}
                </span>
              </div>
              <div className='d-flex align-items-center mb10'>
                <div
                  className='dots'
                  style={{
                    backgroundColor: `${
                      title === 'Users' ? inActiveColor : completeColor
                    }`,
                  }}
                ></div>
                <span className='font_light font_info'>
                  {title === 'Users'
                    ? `${inActive}% Inactive`
                    : `${complete}% Complete`}
                </span>
              </div>
            </div>

            {/* =========== chart =========== */}
            <div className='progress_circle'>
              <CircularProgressbar
                value={title === 'Users' ? active : complete}
                text={`${total}`}
                styles={buildStyles({
                  strokeLinecap: 'butt',
                  trailColor: title === 'Users' ? inActiveColor : completeColor,
                  pathColor: title === 'Users' ? activeColor : progressColor,
                })}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default JobsProgress;
