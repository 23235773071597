import axios from 'axios';
import { DELETE_LOT_FILE } from '../../constants';

export const deleteLotFile = (data, onSuccess, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadDeleteLotFile());
  axios
    .post(`/lots/deleteLotFile`, data, config)
    .then((res) => {
      onSuccess(res);
      dispatch(setDeleteLotFile(res));
    })
    .catch((err) => {
      dispatch(errorDeleteLotFile(err.response));
      onError(err.response);
    });
};

export const loadDeleteLotFile = () => ({
  type: DELETE_LOT_FILE.LOAD,
});

export const setDeleteLotFile = (data) => ({
  type: DELETE_LOT_FILE.SUCCESS,
  payload: data,
});

export const errorDeleteLotFile = (error) => ({
  type: DELETE_LOT_FILE.FAIL,
  payload: error,
});
