import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import loginPageImage from '../assets/images/loginPage.jpeg'
// import AuthCarousel from '../helpers/AuthCarousel';

const AuthLayout = () => {
  return (
    <React.Fragment>
      <Container fluid className='auth_box p-0'>
        <Row className='w-100 m-0 h-100'>
          <Col
            lg={6}
            className='h-100 d-flex justify-content-center align-items-center'
          >
            <Outlet />
          </Col>
          <Col lg={6} className='p-0 d-none d-lg-block h-100'>
            <img src={loginPageImage} alt="not found" className='w-100 h-100' />
            {/* <AuthCarousel className='h-100' /> */}
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default AuthLayout;
