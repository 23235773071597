import axios from 'axios';
import { GET_COMMENT } from '../../constants';

export const fetchComment = (jId, onSuccess, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadGetComment());

  axios
    .get(`user/getComments?jobId=${jId}`, config)
    .then((res) => {
      onSuccess(res);
      dispatch(setGetComment(res));
    })
    .catch((err) => {
      dispatch(errorGetComment(err.response));
      onError(err.response);
    });
};

export const loadGetComment = () => ({
  type: GET_COMMENT.LOAD,
});

export const setGetComment = (lot) => ({
  type: GET_COMMENT.SUCCESS,
  payload: lot.data.data,
});

export const errorGetComment = (error) => ({
  type: GET_COMMENT.FAIL,
  payload: error,
});
