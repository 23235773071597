import React, { useState, useEffect } from 'react';
// Material Ui
import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
// Table Component
import EnhancedTableHead from '../components/Datatable/TableHead';
import EnhancedTableToolbar from '../components/Datatable/TableToolbar';
// Icons
import jobsIcon from '../assets/images/icon/jobsIcon.svg';
import deleteIcon from '../assets/images/icon/deleteIcon.svg';
import editIcon from '../assets/images/icon/editIcon.svg';
// Common Button
import CommonButton from '../components/Button/index';
// import CommonCheckbox from '../components/Checkbox';
import { connect } from 'react-redux';
// import { fetchLotList } from '../actions/lotActions/lotList';
import { searchLot } from '../actions/lotActions/searchLots';
import { deleteLot } from '../actions/lotActions/deleteLot';
import { getErrorMessage } from '../utils/appUtils';
import { useSnackbar } from 'notistack';
import CommonSpinner from '../components/CommonSpinner';
import moment from 'moment';
import Dialog from '../components/Dialog';
import AddLot from './AddLot';
import { ProgressBar } from 'react-bootstrap';
import { Strings } from '../utils/Strings';

// ======== Table Head =========
const headCells = [
  { id: 'title', label: 'Name' },
  { id: 'jobsCompleted', label: 'Jobs completed' },
  { id: 'clientName', label: 'User' },
  { id: 'status', label: 'Status' },
];

const LotsPage = (props) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('title');
  // const [selected, setSelected] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [lotListData, setLotListData] = useState([]);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [selectedLot, setSelectedLot] = useState({});
  const [updateLotDialog, setUpdateLotDialog] = useState(false);

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    props.searchLot(props.searchData, (err) => onError(err));
  }, [
    props.searchData,
    props.deleteLotData,
    props.updateLotData,
    props.addLotData,
  ]);

  // useEffect(() => {
  //   props.fetchLotList((err) => onError(err));
  // }, [props.deleteLotData, props.addLotData, props.updateLotData]);

  useEffect(() => {
    const data = props.searchLotData ? props.searchLotData : [];
    setLotListData(data);
  }, [props.searchLotData]);

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const onDeleteLot = () => {
    props.deleteLot(selectedLot._id, setDeleteDialog, (err) => onError(err));
  };

  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelecteds = lotsData.map((n) => n.title);
  //     setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  // };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }

  //   setSelected(newSelected);
  // };

  // const isSelected = (name) => selected.indexOf(name) !== -1;
  return (
    <Box
      sx={{
        width: '100%',
        height: 'calc(100vh - 78px)',
        overflow: 'auto',
        padding: '0px 24px',
      }}
      className='dataTable_box'
    >
      <Paper sx={{ width: '100%', mb: 2 }} className='dataTable_box'>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        {props.searchLotLoading ? (
          <Box className='spinner-container'>
            <CommonSpinner color='#2c659b' />
          </Box>
        ) : (
          <>
            {lotListData.length === 0 ? (
              <Box className='spinner-container'>
                <p className='no-data-message'>{Strings.LOT_NOTFOUND}</p>
              </Box>
            ) : (
              <TableContainer className='dataTable'>
                <Table sx={{ minWidth: 800 }} aria-labelledby='tableTitle'>
                  <EnhancedTableHead
                    headCells={headCells}
                    // numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    // onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={lotListData.length}
                  />
                  <TableBody>
                    {stableSort(lotListData, getComparator(order, orderBy)).map(
                      (data, index) => {
                        // const isItemSelected = isSelected(data.title);
                        const percentage = Math.round(
                          (data.complete * 100) / data.total
                        );
                        return (
                          <TableRow
                            hover
                            role='checkbox'
                            // aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={data._id}
                            sx={{ width: '100%' }}
                          >
                            {/* <TableCell padding='none'>
                        <CommonCheckbox
                          onChange={(event) => handleClick(event, data.title)}
                          checked={isItemSelected}
                          selected={isItemSelected}
                          className='table_checkbox'
                        />
                      </TableCell> */}
                            <TableCell
                              component='td'
                              scope='row'
                              padding='none'
                            >
                              <Stack direction='column' alignItems='flex-start'>
                                <Typography className='font22 font_light'>
                                  {data.title}
                                </Typography>
                                <Typography className='font_secondary3 font_light font16'>
                                  Created on{' '}
                                  {moment(data.createdAt).format('D MMM, YYYY')}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell className='font_light font16'>
                              <img
                                alt='job'
                                src={jobsIcon}
                                style={{ marginRight: '6px' }}
                              />
                              {data.complete || 0}/{data.total || 0}
                            </TableCell>
                            <TableCell className='font_light font16'>
                              {data.clientName}
                            </TableCell>
                            <TableCell className='font_light font16'>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <ProgressBar
                                  className='lots-progress'
                                  now={percentage || 0}
                                />
                                <span style={{ marginLeft: 5 }}>
                                  {percentage || 0}%
                                </span>
                              </div>
                            </TableCell>
                            <TableCell
                              align='right'
                              className='font_light font16'
                            >
                              <Stack
                                direction='row'
                                alignItems='center'
                                justifyContent='end'
                              >
                                <CommonButton
                                  noMarginTop
                                  className='btn_dataTable'
                                  icon={deleteIcon}
                                  onClick={() => {
                                    setSelectedLot(data);
                                    setDeleteDialog(true);
                                  }}
                                />
                                <CommonButton
                                  noMarginTop
                                  className='btn_dataTable ms-3'
                                  icon={editIcon}
                                  onClick={() => {
                                    setSelectedLot(data);
                                    setUpdateLotDialog(true);
                                  }}
                                />
                              </Stack>
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </>
        )}
      </Paper>
      {updateLotDialog && (
        <AddLot
          title={Strings.EDIT_LOT_TITLE}
          onClose={() => {
            setUpdateLotDialog(false);
          }}
          data={selectedLot}
        />
      )}
      {deleteDialog && (
        <Dialog
          title={Strings.LOT_TITLE}
          message={Strings.DELETE_LOT_MESSAGE}
          onSuccess={onDeleteLot}
          close={() => setDeleteDialog(false)}
          loading={props.deleteLotDataLoading}
        />
      )}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  // lotListData: state.lotListData.data,
  // lotListLoading: state.lotListData.loading,

  searchLotData: state.searchLotData.data,
  searchLotLoading: state.searchLotData.loading,

  deleteLotData: state.deleteLotData.data,
  deleteLotDataLoading: state.deleteLotData.loading,

  addLotData: state.addLotData.data,
  updateLotData: state.updateLotData.data,

  searchData: state.searchData.search,
});

export default connect(mapStateToProps, {
  // fetchLotList,
  deleteLot,
  searchLot,
})(LotsPage);
