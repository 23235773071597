import { ADD_SEARCH } from '../../constants';

const initialState = {
  search: '',
};

export default function (state = initialState, { type, payload } = {}) {
  switch (type) {
    case ADD_SEARCH.SUCCESS:
      return {
        ...state,
        search: payload,
      };
    default:
      return state;
  }
}
