import axios from 'axios';
import { SEARCH_LOT } from '../../constants';

export const searchLot = (word, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadSearchLot());

  axios
    .get(`lots/searchLots?word=${word}`, config)
    .then((res) => dispatch(setSearchLot(res)))
    .catch((err) => {
      dispatch(errorSearchLot(err.response));
      onError(err.response);
    });
};

export const loadSearchLot = () => ({
  type: SEARCH_LOT.LOAD,
});

export const setSearchLot = (lot) => ({
  type: SEARCH_LOT.SUCCESS,
  payload: lot.data.data,
});

export const errorSearchLot = (error) => ({
  type: SEARCH_LOT.FAIL,
  payload: error,
});
