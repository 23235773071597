import React, { Fragment, useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import close from '../../assets/images/close-white.svg';
import CommonButton from '../Button';
import Textbox from '../Textbox';
import { resetPassword } from '../../actions/authActions/resetPassword';
import { connect } from 'react-redux';
import { getErrorMessage, passwordRegex } from '../../utils/appUtils';
import { useSnackbar } from 'notistack';
import { Strings } from '../../utils/Strings';

function ResetPasswordDialog(props) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [error, setError] = useState({ newPassword: '', repeatPassword: '' });

  useEffect(() => {
    const isStrongPassword = passwordRegex.test(newPassword);
    setError({
      ...error,
      newPassword:
        isStrongPassword || !newPassword
          ? ''
          : Strings.VALID_PASSWORD,
    });
  }, [newPassword]);

  useEffect(() => {
    const isSame = newPassword === repeatPassword;
    setError({
      ...error,
      repeatPassword:
        isSame || !repeatPassword
          ? ''
          : Strings.SAME_PASSWORD_TEXT,
    });
  }, [repeatPassword]);

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error',
      });
    }
  };
  const onSuccess = (data) => {
    enqueueSnackbar(data, {
      variant: 'success',
    });
  };
  const onResetPassword = () => {
    props.resetPassword(
      { password: newPassword },
      (err) => onError(err),
      (data) => onSuccess(data)
    );
  };
  return (
    <Fragment>
      <div className='reset_dialog_back_effect' />
      <div className='reset-dialog-container'>
        <div className='reset-dialog-inner-container'>
          <label className='reset-dialog-title' htmlFor='reset-title'>
            {Strings.RESET_PASSWORD_WElCOME_TEXT}
          </label>
          <label
            className='reset-dialog-title reset-dialog-title2'
            htmlFor='reset-title2'
          >
            {Strings.RESET_PASSWORD_EXTRA_TEXT}
          </label>
          <Form className='main_form' style={{ width: '90%' }}>
            <Textbox
              label='New password*'
              name='newPassword'
              type='password'
              placeholder='Enter your new password'
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
              error={error.newPassword}
              value={newPassword}
            />
            <Textbox
              label='Repeat password *'
              name='repeatPassword'
              type='password'
              placeholder='Repeat your new password'
              onChange={(e) => {
                setRepeatPassword(e.target.value);
              }}
              error={error.repeatPassword}
              value={repeatPassword}
            />

            <CommonButton
              text='SAVE'
              noMarginTop
              onClick={onResetPassword}
              loading={props.resetPasswordDataLoading}
              disabled={
                props.resetPasswordDataLoading ||
                !newPassword ||
                !repeatPassword ||
                error.newPassword ||
                error.repeatPassword
              }
            />
          </Form>
          <img
            className='reset_dialog_close_icon'
            src={close}
            alt=''
            onClick={props.onClose}
          />
        </div>
      </div>
    </Fragment>
  );
}
const mapStateToProps = (state) => ({
  resetPasswordDataLoading: state.resetPasswordData.loading,
});

export default connect(mapStateToProps, {
  resetPassword,
})(ResetPasswordDialog);
