import axios from 'axios';
import { UPDATE_JOB } from '../../constants';

export const updateJob = (jId, data, onError, onClose) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadUpdateJob());
  axios
    .put(`jobs/editJob?jobId=${jId}`, data, config)
    .then(async (res) => {
      await onClose();
      await dispatch(setUpdateJob(res));
    })
    .catch((err) => {
      dispatch(errorUpdateJob(err.response));
      onError(err.response);
    });
};

export const loadUpdateJob = () => ({
  type: UPDATE_JOB.LOAD,
});

export const setUpdateJob = (data) => ({
  type: UPDATE_JOB.SUCCESS,
  payload: data.data,
});

export const errorUpdateJob = (error) => ({
  type: UPDATE_JOB.FAIL,
  payload: error,
});
