import React, { Fragment, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import close from '../assets/images/close.svg';
import CommonButton from '../components/Button';
import Dropdown from '../components/Dropdown';
import Textbox from '../components/Textbox';
import CommonCheckbox from '../components/Checkbox';
import { getUserType } from '../utils/constants';
import CountryCodePicker from '../components/CountryCodePicker';
import { connect } from 'react-redux';
import { createUser } from '../actions/superAdminActions/addUser';
import { updateUser } from '../actions/superAdminActions/updateUser';
import { digitsRegex, emailRegex, getErrorMessage } from '../utils/appUtils';
import { useSnackbar } from 'notistack';
import { Strings } from '../utils/Strings';

function AddUser(props) {
  const [userType, setUserType] = useState(0);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [status, setStatus] = useState(true);
  const [countryCode, setCountryCode] = useState('1');
  const [error, setError] = useState({ email: '', phoneNumber: '' });
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (props.title === 'Edit user') {
      const { name, email, status, userType, phoneNumber } = props.data;
      setName(name);
      setEmail(email);
      setUserType(userType);
      setStatus(status);
      const newPhoneNumber = phoneNumber.substring(phoneNumber.length - 10);
      const countryCode = phoneNumber.slice(0, -10).substring(1);
      setPhoneNumber(newPhoneNumber);
      setCountryCode(countryCode);
    }
  }, []);
  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error',
      });
    }
  };

  const createUser = () => {
    if (!emailRegex.test(email)) {
      setError({ email: Strings.VALID_EMAIL, phoneNumber: '' });
    } else if (phoneNumber.length !== 10) {
      setError({
        email: '',
        phoneNumber: Strings.VALID_PHONE_NO,
      });
    } else if (!phoneNumber.match(digitsRegex)) {
      setError({
        email: '',
        phoneNumber: Strings.VALID_ONLY_NUMBER,
      });
    } else {
      const data = {
        name,
        email,
        phoneNumber: `+${countryCode}${phoneNumber}`,
        userType,
        status,
      };
      props.createUser(data, (err) => onError(err), props.onClose);
    }
  };

  const editUser = () => {
    if (!emailRegex.test(email)) {
      setError({ email: Strings.VALID_EMAIL, phoneNumber: '' });
    } else if (phoneNumber.length !== 10) {
      setError({
        email: '',
        phoneNumber: Strings.VALID_PHONE_NO,
      });
    } else if (!phoneNumber.match(digitsRegex)) {
      setError({
        email: '',
        phoneNumber: Strings.VALID_ONLY_NUMBER,
      });
    } else {
      const data = {
        name,
        email,
        phoneNumber: `+${countryCode}${phoneNumber}`,
        userType,
        status,
      };
      props.updateUser(
        props.data._id,
        data,
        (err) => onError(err),
        props.onClose
      );
    }
  };

  const disabledButton = () => {
    const loading =
      props.title === 'Edit user'
        ? props.updateUserLoading
        : props.addUserLoading;

    const editData =
      props.title === 'Edit user'
        ? name === props.data.name &&
          email === props.data.email &&
          status === props.data.status &&
          userType === props.data.userType &&
          props.data.phoneNumber === `+${countryCode}${phoneNumber}`
        : false;
    if (
      !name ||
      !email ||
      !phoneNumber ||
      userType === 0 ||
      loading ||
      editData
    ) {
      return true;
    }
    return false;
  };

  return (
    <Fragment>
      <div className='dialog_back_effect' style={{ zIndex: 2 }} />
      <div className='add_dialog_container'>
        <div className='add_dialog_inner_container'>
          <img
            className='add_dialog_close_icon'
            src={close}
            alt=''
            onClick={props.onClose}
          />
          <label htmlFor='New user' className='dialog_title'>
            {props.title}
          </label>
          <Form className='w-100 addnew_form'>
            <Textbox
              label='Name'
              name='name'
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setError({ email: '', phoneNumber: '' });
              }}
            />
            <Textbox
              type='email'
              label='Email'
              name='email'
              value={email}
              onChange={(e) => {
                setEmail(e.target.value.toLowerCase());
                setError({ email: '', phoneNumber: '' });
              }}
              error={error.email}
            />
            <Form.Group
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <CountryCodePicker
                value={countryCode}
                onChange={(e) => {
                  setCountryCode(e.target.value);
                  setError({ email: '', phoneNumber: '' });
                }}
                label='Phone'
              />
              <Textbox
                type='number'
                name='phone'
                value={phoneNumber}
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                  setError({ email: '', phoneNumber: '' });
                }}
                error={error.phoneNumber}
              />
            </Form.Group>
            <Dropdown
              label='User type'
              title='Select an option'
              optionArray={getUserType()}
              value={userType}
              onChange={(e) => {
                setUserType(e.target.value);
                setError({ email: '', phoneNumber: '' });
              }}
            />
            <Form.Group
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Form.Label
                htmlFor={'status'}
                className={`font16 font_light form_label`}
              >
                Status
              </Form.Label>
              <Form.Group style={{ display: 'flex', alignItems: 'center' }}>
                <CommonCheckbox
                  type='switch'
                  className='switch'
                  checked={status}
                  onChange={(e) => {
                    setStatus(e.target.checked);
                    setError({ email: '', phoneNumber: '' });
                  }}
                />
                <Form.Label
                  htmlFor={'status'}
                  className={`font16 font_light form_label`}
                  style={{ marginLeft: 10 }}
                >
                  Active
                </Form.Label>
              </Form.Group>
            </Form.Group>
            <CommonButton
              text={props.title === 'Edit user' ? 'EDIT USER' : 'ADD NEW USER'}
              onClick={() =>
                props.title === 'Edit user' ? editUser() : createUser()
              }
              loading={
                props.title === 'Edit user'
                  ? props.updateUserLoading
                  : props.addUserLoading
              }
              disabled={disabledButton()}
            />
          </Form>
        </div>
      </div>
    </Fragment>
  );
}
const mapStateToProps = (state) => ({
  addUserLoading: state.addUserData.loading,
  updateUserLoading: state.updateUserData.loading,
});
export default connect(mapStateToProps, {
  createUser,
  updateUser,
})(AddUser);
