import './assets/styles/App.css';
import 'react-circular-progressbar/dist/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import React from 'react';
import Routes from './routes';
import { BrowserRouter } from 'react-router-dom';
import store from './store/index';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
// routes

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <SnackbarProvider
          autoHideDuration={3000}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          maxSnack={3}
        >
          <Routes />
        </SnackbarProvider>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
