import axios from 'axios';
import { ADD_JOB } from '../../constants';

export const createJob = (data, onError, onClose) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(addJob());
  axios
    .post(`jobs/createJob`, data, config)
    .then((res) => {
      dispatch(setJob(res));
      onClose();
    })
    .catch((err) => {
      dispatch(errorJob(err.response));
      onError(err.response);
    });
};

export const addJob = () => ({
  type: ADD_JOB.LOAD,
});

export const setJob = (data) => ({
  type: ADD_JOB.SUCCESS,
  payload: data.data,
});

export const errorJob = (error) => ({
  type: ADD_JOB.FAIL,
  payload: error,
});
