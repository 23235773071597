import jwtDecode from 'jwt-decode';
import dashIcon from '../assets/images/icon/dashIcon.png';
import userIcon from '../assets/images/icon/userIcon.png';
import lotsIcon from '../assets/images/icon/lotsIcon.png';
import jobsIcon from '../assets/images/icon/jobsIcon.png';
import { Strings } from './Strings';

const menuList = () => {
  return jwtDecode(localStorage.jwtToken).userType === 'client'
    ? [
        {
          icon: jobsIcon,
          title: 'Jobs',
          path: '/app/jobs',
        },
      ]
    : [
        {
          icon: dashIcon,
          title: Strings.DASHBOARD_TITLE,
          path: '/app/dashboard',
        },
        {
          icon: userIcon,
          title: Strings.USER_TITLE,
          path: '/app/users',
          eventKey: 2,
        },
        {
          icon: lotsIcon,
          title: Strings.LOT_TITLE,
          path: '/app/lots',
          eventKey: 3,
        },
        {
          icon: jobsIcon,
          title: Strings.JOB_TITLE,
          path: '/app/jobs',
          eventKey: 4,
        },
      ];
};

export { menuList };
