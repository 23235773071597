import { GET_LOT_FILE } from "../../constants";

const initialState = {
    data: null,
    loading: false,
    error: {}
}

const getLotFileReducer = (state = initialState, { type, payload } = {}) => {
    switch (type) {
        case GET_LOT_FILE.LOAD:
            return {
                ...state,
                loading: true
            }
        case GET_LOT_FILE.SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload
            }
        case GET_LOT_FILE.FAIL:
            return {
                ...state,
                loading: false,
                error: payload
            }
        default:
            return state;
    }
}

export default getLotFileReducer