import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import ProgressCard from '../components/card/ProgressCard';
import LotsData from '../components/card/LotsData';
// import TopEngagement from '../components/card/TopEngagment';
// import { Link } from 'react-router-dom';
import JobsData from '../components/card/JobsData';
import LinkButton from '../components/LinkButton';
import { connect } from 'react-redux';
import { fetchMonthlyProgress } from '../actions/superAdminActions/getMonthlyProgress';
import { getErrorMessage } from '../utils/appUtils';
import { useSnackbar } from 'notistack';
import { fetchLotList } from '../actions/lotActions/lotList';
import { fetchJobList } from '../actions/jobActions/jobList';
import { Strings } from '../utils/Strings';

const Dashboard = (props) => {
  const [monthlyProgressData, setMonthlyProgressData] = useState(null);
  const [lotListData, setLotListData] = useState([]);
  const [jobListData, setJobListData] = useState({});
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error',
      });
    }
  };
  useEffect(() => {
    props.fetchMonthlyProgress((err) => onError(err));
    props.fetchLotList((err) => onError(err), true);
    props.fetchJobList((err) => onError(err), true);
  }, []);

  useEffect(() => {
    const data = props.getMonthlyProgressData
      ? props.getMonthlyProgressData
      : null;
    setMonthlyProgressData(data);
  }, [props.getMonthlyProgressData]);

  useEffect(() => {
    const data = props.lotListData ? props.lotListData : [];
    setLotListData(data);
  }, [props.lotListData]);

  useEffect(() => {
    const data = props.jobListData ? props.jobListData : {};
    setJobListData(data);
  }, [props.jobListData]);

  return (
    <div className='dashboard-container'>
      <div className='w-100 mt-3'>
        <span className='font_light'>This month</span>
      </div>
      {/* ==== Progress Bar ==== */}
      <div className='mt13'>
        <Row>
          {/* === Job Progress === */}
          <Col md={6} lg={4} className='mb-4 mb-lg-0'>
            <ProgressCard
              title={Strings.JOB_TITLE}
              data={monthlyProgressData?.Jobs}
              loading={props.getMonthlyProgressLoading}
            />
          </Col>
          {/* === Lot Progress === */}
          <Col md={6} lg={4} className='mb-4 mb-lg-0'>
            <ProgressCard
              title={Strings.LOT_TITLE}
              data={monthlyProgressData?.Lots}
              loading={props.getMonthlyProgressLoading}
            />
          </Col>
          {/* === User Progress === */}
          <Col md={6} lg={4} className='mb-4 mb-lg-0'>
            <ProgressCard
              title={Strings.USER_TITLE}
              data={monthlyProgressData?.Users}
              loading={props.getMonthlyProgressLoading}
            />
          </Col>
          {/* === Top Engagement === */}
          {/* <Col md={6} lg={3} className='mb-4 mb-lg-0'>
            <div className='progress_card'>
              <TopEngagement />
            </div>
          </Col> */}
        </Row>
      </div>

      {/* =========== Lots & Jobs Data =========== */}
      <div className='mt24'>
        <Row>
          {/* ============ Lots Data ============ */}
          <Col lg={6} className='mb-4 mb-lg-0'>
            <div className='dataBox common_datacard'>
              <div className='w-100 d-flex justify-content-between align-items-center'>
                <span className='font22 font_light'>{Strings.LOT_TITLE}</span>
                <LinkButton
                  to='/app/lots'
                  className='font700'
                  text='View all'
                />
              </div>
              {/* ============ Lots Data From Component ============ */}
              <LotsData data={lotListData} loading={props.lotListLoading} />
            </div>
          </Col>
          {/* ============ Jobs Data ============ */}
          <Col lg={6} className='mb-4 mb-lg-0'>
            <div className='dataBox common_datacard'>
              <div className='w-100 d-flex justify-content-between align-items-center'>
                <span className='font22 font_light'>{Strings.JOB_TITLE}</span>
                <LinkButton
                  to='/app/jobs'
                  className='font700'
                  text='View all'
                />
              </div>
              {/* ============ Jobs Data From Component ============ */}
              <JobsData
                data={jobListData && jobListData}
                loading={props.jobListLoading}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  getMonthlyProgressData: state.getMonthlyProgressData.data,
  getMonthlyProgressLoading: state.getMonthlyProgressData.loading,

  lotListData: state.lotListData.data,
  lotListLoading: state.lotListData.loading,

  jobListData: state.jobListData.data,
  jobListLoading: state.jobListData.loading,
});

export default connect(mapStateToProps, {
  fetchMonthlyProgress,
  fetchLotList,
  fetchJobList,
})(Dashboard);
