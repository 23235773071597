import React, { useEffect, useState } from 'react';
import addnew from '../assets/images/addnew.svg';
import SearchBar from './SearchBar';
import { useLocation } from 'react-router-dom';
import AddUser from '../pages/AddUser';
import AccountPopover from './AccountPopover';
import CommonButton from '../components/Button';
import AddLot from '../pages/AddLot';
import AddJobs from '../pages/AddJobs';
import ResetPasswordDialog from '../components/ResetPasswordDialog';
import jwtDecode from 'jwt-decode';
import { Strings } from '../utils/Strings';
import { userType } from '../utils/appUtils';
import { connect } from 'react-redux';
import { setSearchData } from '../actions/commonActions/setSearch';

const NavBar = (props) => {
  const { pathname } = useLocation();
  const [pageTitle, setPageTitle] = useState('');
  const [addNewDialog, setAddNewDialog] = useState(false);
  const [resetPasswordDialog, setResetPasswordDialog] = useState(false);
  const [search, setSearch] = useState('');

  useEffect(() => {
    const splitTitle = pathname.split('/app/');
    const capitalizeTitle =
      splitTitle[`1`]?.charAt(0)?.toUpperCase() + splitTitle[`1`]?.slice(1);
    setPageTitle(capitalizeTitle);
  }, [pathname]);

  useEffect(() => {
    const decodedToken = jwtDecode(localStorage.jwtToken);
    if (decodedToken.userType === 'client') {
      if (decodedToken.firstLogin) {
        setResetPasswordDialog(true);
      }
    }
  }, []);

  useEffect(() => {
    setSearch('');
  }, [pageTitle]);

  useEffect(() => {
    props.setSearchData(search);
  }, [search]);

  return (
    <React.Fragment>
      <div className='top-bar d-flex justify-content-between align-items-center header'>
        <div className='d-flex'>
          {/* ================ Page Title ============== */}
          <span className='font_light font32 page_head'>{pageTitle}</span>
          {/* ================ Add Button ============== */}
          {(pathname === '/app/users' ||
            pathname === '/app/lots' ||
            (pathname === '/app/jobs' && userType() !== 'client')) && (
            <CommonButton
              text='New'
              className='addnew_button'
              icon={addnew}
              iconClassName='addnew_icon'
              noCommonButton
              noMarginTop
              onClick={() => {
                setAddNewDialog(true);
              }}
            />
          )}
        </div>

        <div className='d-flex align-items-center'>
          {/* =============== Search =============== */}
          {(pageTitle === 'Jobs' || pageTitle === 'Lots') && (
            <SearchBar
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          )}

          {/* ============ Profile Image Popover ============ */}
          <AccountPopover />
        </div>
      </div>
      {addNewDialog && pathname === '/app/users' && (
        <AddUser
          title={Strings.ADD_USER_TITLE}
          onClose={() => {
            setAddNewDialog(false);
          }}
        />
      )}
      {addNewDialog && pathname === '/app/jobs' && (
        <AddJobs
          title='Add job'
          onClose={() => {
            setAddNewDialog(false);
          }}
        />
      )}
      {addNewDialog && pathname === '/app/lots' && (
        <AddLot
          title={Strings.ADD_LOT_TITLE}
          onClose={() => {
            setAddNewDialog(false);
          }}
        />
      )}
      {resetPasswordDialog && (
        <ResetPasswordDialog onClose={() => setResetPasswordDialog(false)} />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  setSearchData,
})(NavBar);
