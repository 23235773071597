import React from 'react';
import { Form } from 'react-bootstrap';
import { COUNTRIES } from '../../utils/constants';

function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}

export default function CountryCodePicker(props) {
  const { label, onChange } = props;
  return (
    <Form.Group style={{ marginRight: 5 }}>
      <Form.Label htmlFor={label} className={`font16 font_light form_label`}>
        {label}
      </Form.Label>
      <Form.Select
        value={props.value ? props.value : '1'}
        onChange={onChange}
        className={`${!props.error && 'mb16'} form_dropdown`}
        style={{ minWidth: 115 }}
      >
        {COUNTRIES.map((option, index) => (
          <option key={`country${index}`} value={option.phone}>
            {`${countryToFlag(option.code)} +${option.phone}`}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  );
}
