import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Form } from 'react-bootstrap';
import close from '../assets/images/close.svg';
import fileIcon from '../assets/images/./icon/fileIcon.svg';
import CommonButton from '../components/Button';
import Dropdown from '../components/Dropdown';
import Textbox from '../components/Textbox';
import { connect } from 'react-redux';
import { uploadFile } from '../actions/superAdminActions/uploadFile';
import {
  getErrorMessage,
  getFileExtention,
  getFileName,
} from '../utils/appUtils';
import { useSnackbar } from 'notistack';
import { fetchClientList } from '../actions/lotActions/clientList';
import { fetchLot } from '../actions/lotActions/getLot';
import { deleteLotFile } from '../actions/lotActions/deleteLotFile';
import { createLot } from '../actions/lotActions/addLot';
import { updateLot } from '../actions/lotActions/updateLot';
import CommonSpinner from '../components/CommonSpinner';
import { Box } from '@mui/material';
import { FileDrop } from 'react-file-drop';
import Dialog from '../components/Dialog';
import { Strings } from '../utils/Strings';

function AddLot(props) {
  const [client, setClient] = useState(0);
  const [title, setTitle] = useState('');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [clientListData, setClientListData] = useState([]);
  const [files, setFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const inputFile = useRef(null);
  const [onDropEffect, setOnDropEffect] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [singleFileObj, setSingleFileObj] = useState();

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    props.fetchClientList((err) => onError(err));
    if (props.title === 'Edit lot') {
      props.fetchLot(props.data._id, (err) => onError(err));
    }
  }, []);

  useEffect(() => {
    if (props.title === 'Edit lot') {
      const data = props.getLotData ? props.getLotData : null;
      if (data) {
        setClient(data.clientId);
        setTitle(data.title);
        setFiles(data.files);
      }
    }
  }, [props.getLotData]);

  useEffect(() => {
    const data = props.clientListData ? props.clientListData : [];
    const newData = data.map((item) => ({
      title: item.name,
      value: item._id,
    }));
    setClientListData(newData);
  }, [props.clientListData]);

  const addLot = () => {
    const data = {
      title,
      clientId: client,
      files,
    };
    props.createLot(data, (err) => onError(err), props.onClose);
  };

  const disabledButton = () => {
    const loading =
      props.title === 'Edit lot' ? props.updateLotLoading : props.addLotLoading;

    if (!title || client === 0 || loading || props.deleteLotFileLoading) {
      return true;
    }
    return false;
  };

  const onSelectFile = () => {
    inputFile.current.click();
  };

  const onFileUpload = (res) => {
    if (res) {
      setFiles([...files, ...res]);
      if (props.title === 'Edit lot')
        setUploadedFiles([...uploadedFiles, ...res]);
    }
  };

  const handleLotFileUpload = async (files, input) => {
    let newFiles;
    if (input === 'input') {
      newFiles = files.target.files;
    } else {
      newFiles = files;
    }
    const data = {
      files: newFiles,
    };

    props.uploadFile(
      data,
      (res) => onFileUpload(res),
      (err) => onError(err)
    );
    setOnDropEffect(false);
  };

  const onDeleteFileResponse = (res, fileUrl) => {
    if (res.data.status === 200) {
      const newFiles = files.filter((fileData) => fileData.url !== fileUrl);
      setFiles(newFiles);
      setOpenDeleteDialog(false)
      if (props.title === 'Edit lot') {
        const newFiles = uploadedFiles.filter(
          (fileData) => fileData.url !== fileUrl
        );
        setUploadedFiles(newFiles);
      }
    }
  };

  const onDeleteFile = (file) => {
    if(file){
      let tempfile = { url: file.url };
      if (file._id) tempfile.id = file._id;
      const data = {
        file: tempfile,
      };
      if (props.data && props.data._id) data.lotId = props.data._id;
      props.deleteLotFile(
        data,
        (res) => onDeleteFileResponse(res, file.url),
        (err) => onError(err)
      );
    }
  };

  const editLot = () => {
    const data = {
      lotId: props.data._id,
      title,
      clientId: client,
      files: uploadedFiles,
    };
    props.updateLot(data, (err) => onError(err), props.onClose);
  };

  const openFile = (fileUrl) => {
    window.open(fileUrl)
  }

  return (
    <Fragment>
      {openDeleteDialog && (
        <Dialog
          title={Strings.FILE_TITLE}
          message={Strings.DELETE_FILE_MESSAGE}
          close={() => setOpenDeleteDialog(false)}
          onSuccess={() => onDeleteFile(singleFileObj && singleFileObj)}
          loading={props?.deleteLotFileLoading}
        />
      )}
      <div className='dialog_back_effect' style={{ zIndex: 2 }} />
      <div className='add_dialog_container'>
        <div className='add_dialog_inner_container'>
          <img
            className='add_dialog_close_icon'
            src={close}
            alt=''
            onClick={props.onClose}
          />
          <label htmlFor='New user' className='dialog_title'>
            {props.title}
          </label>
          {props.clientListLoading ? (
            <Box className='spinner-container'>
              <CommonSpinner color='#2c659b' />
            </Box>
          ) : (
            <Form className='w-100 addnew_form'>
              <Textbox
                label='Title'
                title='title'
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
              <Dropdown
                label='Client'
                title='Select an option'
                optionArray={
                  props.title === 'Edit lot'
                    ? [
                        ...clientListData,
                        {
                          title: props.getLotData && props.getLotData.name,
                          value: props.getLotData && props.getLotData.clientId,
                        },
                      ]
                    : clientListData
                }
                value={client}
                onChange={(e) => {
                  setClient(e.target.value);
                }}
                noBottomMargin
              />
              <Form.Label
                htmlFor='files'
                className={`font16 font_light dropbox_label`}
              >
                Files
              </Form.Label>
              <FileDrop
                onDragOver={(event) =>
                  event && !props.uploadFileLoading && setOnDropEffect(true)
                }
                onDragLeave={(event) =>
                  event && !props.uploadFileLoading && setOnDropEffect(false)
                }
                onDrop={(files) =>
                  !props.uploadFileLoading && handleLotFileUpload(files)
                }
              >
                <div
                  className={`file-drop-container ${
                    onDropEffect && 'file-drop-container-effect'
                  }`}
                >
                  {props.uploadFileLoading ? (
                    <CommonSpinner color='#2c659b' />
                  ) : (
                    <div className='drop-text-container'>
                      <input
                        style={{ display: 'none' }}
                        ref={inputFile}
                        onChange={(event) =>
                          handleLotFileUpload(event, 'input')
                        }
                        type='file'
                        accept='/*'
                        multiple
                      />
                      <span
                        className='drop-browse-button'
                        onClick={onSelectFile}
                      >
                        Click to browse
                      </span>
                      &nbsp;or drag and drop your files
                    </div>
                  )}
                </div>
              </FileDrop>
              <div
                className='uploaded-file-container'
                style={{ marginTop: files.length ? 10 : 0 }}
              >
                {files.map((file, index) => {
                  const extension = getFileExtention(file.fileName);

                  const fileName = getFileName(file.fileName);

                  return (
                    <div
                      key={`file${index}`}
                      className='file-component-container'
                    >
                      <div
                        className='file_name_container'
                        style={{ cursor: 'pointer' }}
                        onClick={() => openFile(file.url)}
                      >
                        <img src={fileIcon} className='uploaded-image' alt='' />
                        <label className='file_name'>{fileName}</label>
                        <span>.{extension}</span>
                      </div>
                      <img
                        className='delete_file_icon'
                        src={close}
                        alt=''
                        onClick={() =>{
                          !props.deleteLotFileLoading &&
                          setSingleFileObj(file)
                          setOpenDeleteDialog(true)
                        }
                        }
                      />
                    </div>
                  );
                })}
              </div>
              <CommonButton
                text={props.title === 'Edit lot' ? 'EDIT LOT' : 'ADD NEW LOT'}
                onClick={() =>
                  props.title === 'Edit lot' ? editLot() : addLot()
                }
                loading={
                  props.title === 'Edit lot'
                    ? props.updateLotLoading
                    : props.addLotLoading
                }
                disabled={disabledButton()}
              />
            </Form>
          )}
        </div>
      </div>
    </Fragment>
  );
}
const mapStateToProps = (state) => ({
  clientListData: state.clientListData.data,
  clientListLoading: state.clientListData.loading,

  uploadFileLoading: state.uploadFileData.loading,

  addLotLoading: state.addLotData.loading,

  getLotData: state.getLotData.data,
  getLotLoading: state.getLotData.loading,
  updateLotLoading: state.updateLotData.loading,
  deleteLotFileLoading: state.deleteLotFileData.loading,
});
export default connect(mapStateToProps, {
  fetchClientList,
  uploadFile,
  createLot,
  fetchLot,
  deleteLotFile,
  updateLot,
})(AddLot);
