import React from 'react';
import Avatar from '@mui/material/Avatar';
import moment from 'moment';
import CommonButton from '../Button';
import deleteIcon from '../../assets/images/icon/blackDeleteIcon.svg';
import fileIcon from '../../assets/images/icon/fileIcon.svg';
import { getFileExtention, getFileName } from '../../utils/appUtils';

const AddComment = (props) => {
  const {
    jobCreatedName,
    date,
    message,
    data,
    mainUserType,
    mainUserId,
    onDeleteComment,
  } = props;
  const commentUserType = data.authorId.userType;
  const userId = data.authorId._id;

  const showDeleteOption = () => {
    if (mainUserType === 'superadmin') return true;
    else if (
      (mainUserType === 'manager' && userId === mainUserId) ||
      (mainUserType === 'manager' && commentUserType === 'client')
    )
      return true;
    else if (mainUserType === 'client' && userId === mainUserId) return true;
    return false;
  };

  const imageShow = (url) => {
    window.open(url);
  };

  return (
    <div
      className='d-flex flex-column w-100 mt19'
      style={{ position: 'relative' }}
    >
      <div className='commentDeleteIcon'>
        {showDeleteOption() && (
          <CommonButton
            icon={deleteIcon}
            noMarginTop
            className='btn_dataTable ms-2 d-flex align-items-center'
            onClick={() => {
              onDeleteComment(data._id);
            }}
          />
        )}
      </div>
      {message || data?.images.length > 0 ? (
        <div className='comment-card'>
          {data && data?.images
            ? data?.images.map((e, index) => {
                const fileName = getFileName(e.fileName);
                const extension = getFileExtention(e.fileName);
                return (
                  <div
                    className='d-flex align-items-center job_file_name_container'
                    style={{ cursor: 'pointer', marginBottom: 5 }}
                    key={index}
                    onClick={() => imageShow(e.url)}
                  >
                    <img src={fileIcon} alt={data.type} className='me-2' />
                    <div
                      className='d-flex flex-column'
                      style={{ marginLeft: 10, width: '95%' }}
                    >
                      <span className='font_light font_secondary2 d-flex'>
                        <span
                          className='d-inline-block mb-1 file_name'
                          style={{
                            marginLeft: 0,
                            maxWidth: '75%',
                          }}
                        >
                          {fileName}
                        </span>
                        <span>.{extension}</span>
                      </span>
                    </div>
                  </div>
                );
              })
            : null}
          {message}
        </div>
      ) : null}
      <div className='d-flex align-items-center mt-2'>
        <Avatar sx={{ height: '35px', width: '35px', bgcolor: '#2c659b' }}>
          {jobCreatedName[0].toUpperCase()}
        </Avatar>
        <div className='d-flex flex-column ms-2'>
          <span className='font400 font_secondary2 font16'>
            by {jobCreatedName ? jobCreatedName : ''}
          </span>
          <span className='font600 font_secondary3 font12'>
            {/* on {date ? date : ''} */}
            on {date ? moment(date).format('DD MMM, YYYY - h:mma') : ''}
          </span>
        </div>
      </div>
    </div>
  );
};

export default AddComment;
