import { Avatar, Box } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import calenderIcon from '../../assets/images/icon/calenderIcon.svg'
import messageIcon from '../../assets/images/icon/messageIcon.svg'
import { Strings } from '../../utils/Strings'
import CommonSpinner from '../CommonSpinner'
// import GroupImages from '../GroupImages'

const JobsDataCard = (props) => {
    const { jobsArray, loading } = props;
    return (
        <React.Fragment>
            <div className='w-100'>
                {loading ? (
                    <div className='progress_chart d-flex justify-content-center align-items-center'>
                        <CommonSpinner color='#58585a' />
                    </div>
                ) : jobsArray?.length === 0 ? (
                    <Box className='spinner-container'>
                        <p className='no-data-message'>{Strings.JOB_NOTFOUND}</p>
                    </Box>
                ) : (
                    <Row>
                        {
                            jobsArray && jobsArray.map((data, index) => {
                                return (
                                    <Col sm={12} className="mt-4" key={index}>
                                        <div className='job_dataCard common_datacard'>
                                            {/* ======= Title & Images ======= */}
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <span className='font22 font_light mb-2 mb-md-0'>{data.title}</span>
                                                <Avatar sx={{ bgcolor: '#2c659b' }}>
                                                    {data.assignedTo[0]?.name[0]?.toUpperCase()}
                                                </Avatar>
                                                {/* <GroupImages images={data.assignedTo} /> */}
                                            </div>
                                            {/* ======= AML & Priority ======= */}
                                            <div className='d-flex align-items-center mt13'>
                                                <div className='btn_job aml_box font12 font600'>{data.lots[0]?.title}</div>
                                                {/* <div className='btn_job priority_box font12 font600'>{data.prior}</div> */}
                                            </div>
                                            {/* ======== Date & Message ======= */}
                                            <div className='d-flex flex-column flex-md-row align-items-md-center justify-content-md-between mt13'>
                                                <div className='d-flex align-items-center mb-2 mb-md-0'>
                                                    <img src={calenderIcon} alt="not found" />
                                                    <span className='font_light ms-2'>{moment(data?.dueDate)?.format('DD MMM, YYYY')}</span>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <img src={messageIcon} alt="not found" />
                                                    <span className='font_light ms-2'>{data.comments && data.comments.length}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                )}
            </div>
        </React.Fragment>
    )
}

export default JobsDataCard