import React from 'react';
import { Outlet } from 'react-router-dom';
import SidebarMenu from '../helpers/Sidebar';
import NavBar from '../helpers/Navbar';

const MainLayout = () => {
  return (
    <React.Fragment>
      <div className='d-flex align-items-stretch'>
        <SidebarMenu />
        <div className='main_layout'>
          <NavBar />
          <Outlet />
        </div>
      </div>
    </React.Fragment>
  );
};

export default MainLayout;
