import axios from 'axios';
import setAuthToken from '../../utils/setAuthToken';
import { LOGIN } from '../../constants';

export const login =
  (loginData, navigate, onError, rememberMe) => (dispatch) => {
    dispatch(loadLogin());
    axios
      .post('auth/login', loginData)
      .then((res) => {
        const { token, name, profilePicture } = res.data.data;
        setAuthToken(token);
        localStorage.setItem('jwtToken', token);
        localStorage.setItem('userName', name);
        if (profilePicture)
          localStorage.setItem('profilePicture', profilePicture);
        if (rememberMe) {
          localStorage.setItem('rememberMe', true);
          localStorage.setItem('email', loginData.email);
          localStorage.setItem('password', loginData.password);
        } else {
          localStorage.removeItem('rememberMe');
          localStorage.removeItem('email');
          localStorage.removeItem('password');
        }
        navigate('/');
        dispatch(setLogin(res.data.data));
      })
      .catch((err) => {
        dispatch(errorLogin(err.response));
        onError(err.response);
      });
  };

export const loadLogin = () => ({
  type: LOGIN.LOAD,
});

export const setLogin = (data) => ({
  type: LOGIN.SUCCESS,
  payload: data,
});

export const errorLogin = (error) => ({
  type: LOGIN.FAIL,
  payload: error,
});

export const logout = (navigate) => (dispatch) => {
  dispatch(errorLogin({}));
  localStorage.removeItem('jwtToken');
  localStorage.removeItem('userName');
  localStorage.removeItem('profilePicture');
  setAuthToken(false);
  dispatch(setLogin({}));
  if (navigate) navigate('/login');
};
