import axios from 'axios';
import { GET_JOB } from '../../constants';

export const fetchJob = (jId, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadGetJob());

  axios
    .get(`jobs/getJob?jobId=${jId}`, config)
    .then((res) => dispatch(setGetJob(res)))
    .catch((err) => {
      dispatch(errorGetJob(err.response));
      onError(err.response);
    });
};

export const loadGetJob = () => ({
  type: GET_JOB.LOAD,
});

export const setGetJob = (lot) => ({
  type: GET_JOB.SUCCESS,
  payload: lot.data.data,
});

export const errorGetJob = (error) => ({
  type: GET_JOB.FAIL,
  payload: error,
});
