import React from 'react';
import { Box } from '@mui/material'
import { Col, ProgressBar, Row } from 'react-bootstrap';
// import { lotsData } from '../../utils/LotsData';
import CommonSpinner from '../CommonSpinner';
import { Strings } from '../../utils/Strings';

const LotsData = (props) => {
  const { loading, data } = props;
  return (
    <React.Fragment>
      {loading ? (
        <div className='progress_chart d-flex justify-content-center align-items-center'>
          <CommonSpinner color='#58585a' />
        </div>
      ) : data?.length === 0 ? (
        <Box className='spinner-container'>
          <p className='no-data-message'>{Strings.LOT_NOTFOUND}</p>
        </Box>
      ) : (
        <div className='w-100'>
          <Row>
            {data &&
              data.map((data, index) => {
                const percentage = Math.round(
                  (data.complete * 100) / data.total
                );
                return (
                  <Col md={6} key={index} className='mt-4'>
                    <div className='data_card common_datacard d-flex flex-column align-items-center'>
                      <ProgressBar now={percentage} />
                      <span className='font700 mt19 text-center'>
                        {data.title}
                      </span>
                      <div className='d-flex flex-column align-items-center mt19'>
                        <div>
                          <span className='font700 font24'>
                            {data.complete || 0}
                          </span>
                          <span className='font24 font_extraLight'>
                            {' '}
                            / {data.total || 0}
                          </span>
                        </div>
                      </div>
                      <span className='font_secondary3 font400'>
                        {Strings.JOB_COMPLETE}
                      </span>
                      <span className='font700 mt19 text-center'>
                        {data.clientName}
                      </span>
                      {/* <div className='mt19 d-flex'>
                                            <GroupImages images={data.images} />
                                        </div> */}
                    </div>
                  </Col>
                );
              })}
          </Row>
        </div>
      )}
    </React.Fragment>
  );
};

export default LotsData;
