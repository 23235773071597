const LOGIN = {
  LOAD: 'LOAD_LOGIN',
  SUCCESS: 'SUCCESS_LOGIN',
  FAIL: 'FAIL_LOGIN',
};

const FORGOT_PASSWORD = {
  LOAD: 'LOAD_FORGOT_PASSWORD',
  SUCCESS: 'SUCCESS_FORGOT_PASSWORD',
  FAIL: 'FAIL_FORGOT_PASSWORD',
};

const ADD_USER = {
  LOAD: 'LOAD_ADD_USER',
  SUCCESS: 'SUCCESS_ADD_USER',
  FAIL: 'FAIL_ADD_USER',
};

const USER_LIST = {
  LOAD: 'LOAD_USER_DATA',
  SUCCESS: 'SUCCESS_USER_DATA',
  FAIL: 'FAIL_USER_DATA',
};

const UPDATE_USER = {
  LOAD: 'LOAD_UPDATE_USER',
  SUCCESS: 'SUCCESS_UPDATE_USER',
  FAIL: 'FAIL_UPDATE_USER',
};

const DELETE_USER = {
  LOAD: 'LOAD_DELETE_USER',
  SUCCESS: 'SUCCESS_DELETE_USER',
  FAIL: 'FAIL_DELETE_USER',
};

const LOT_LIST = {
  LOAD: 'LOAD_LOT_DATA',
  SUCCESS: 'SUCCESS_LOT_DATA',
  FAIL: 'FAIL_LOT_DATA',
};

const DELETE_LOT = {
  LOAD: 'LOAD_DELETE_LOT',
  SUCCESS: 'SUCCESS_DELETE_LOT',
  FAIL: 'FAIL_DELETE_LOT',
};

const CLIENT_LIST = {
  LOAD: 'LOAD_CLIENT_DATA',
  SUCCESS: 'SUCCESS_CLIENT_DATA',
  FAIL: 'FAIL_CLIENT_DATA',
};

const UPLOAD_FILE = {
  LOAD: 'LOAD_UPLOAD_FILE',
  SUCCESS: 'SUCCESS_UPLOAD_FILE',
  FAIL: 'FAIL_UPLOAD_FILE',
};

const ADD_LOT = {
  LOAD: 'LOAD_ADD_LOT',
  SUCCESS: 'SUCCESS_ADD_LOT',
  FAIL: 'FAIL_ADD_LOT',
};

const GET_LOT = {
  LOAD: 'LOAD_GET_LOT',
  SUCCESS: 'SUCCESS_GET_LOT',
  FAIL: 'FAIL_GET_LOT',
};

const GET_LOT_FILE = {
  LOAD: 'LOAD_GET_LOT_FILE',
  SUCCESS: 'SUCCESS_GET_LOT_FILE',
  FAIL: 'FAIL_GET_LOT_FILE',
};

const UPDATE_LOT = {
  LOAD: 'LOAD_UPDATE_LOT',
  SUCCESS: 'SUCCESS_UPDATE_LOT',
  FAIL: 'FAIL_UPDATE_LOT',
};

const DELETE_LOT_FILE = {
  LOAD: 'LOAD_DELETE_LOT_FILE',
  SUCCESS: 'SUCCESS_DELETE_LOT_FILE',
  FAIL: 'FAIL_DELETE_LOT_FILE',
};

const GET_MONTHLY_PROGRESS = {
  LOAD: 'LOAD_GET_MONTHLY_PROGRESS',
  SUCCESS: 'SUCCESS_GET_MONTHLY_PROGRESS',
  FAIL: 'FAIL_GET_MONTHLY_PROGRESS',
};

const JOB_LIST = {
  LOAD: 'LOAD_JOB_DATA',
  SUCCESS: 'SUCCESS_JOB_DATA',
  FAIL: 'FAIL_JOB_DATA',
};

const DELETE_JOB = {
  LOAD: 'LOAD_DELETE_JOB',
  SUCCESS: 'SUCCESS_DELETE_JOB',
  FAIL: 'FAIL_DELETE_JOB',
};

const DELETE_JOB_FILE = {
  LOAD: 'LOAD_DELETE_JOB_FILE',
  SUCCESS: 'SUCCESS_DELETE_JOB_FILE',
  FAIL: 'FAIL_DELETE_JOB_FILE',
};

const RESET_PASSWORD = {
  LOAD: 'LOAD_RESET_PASSWORD',
  SUCCESS: 'SUCCESS_RESET_PASSWORD',
  FAIL: 'FAIL_RESET_PASSWORD',
};

const GET_CLIENT_MONTHLY_PROGRESS = {
  LOAD: 'LOAD_GET_CLIENT_MONTHLY_PROGRESS',
  SUCCESS: 'SUCCESS_GET_CLIENT_MONTHLY_PROGRESS',
  FAIL: 'FAIL_GET_CLIENT_MONTHLY_PROGRESS',
};

const ADD_JOB = {
  LOAD: 'LOAD_ADD_JOB',
  SUCCESS: 'SUCCESS_ADD_JOB',
  FAIL: 'FAIL_ADD_JOB',
};

const ADD_SEARCH = {
  SUCCESS: 'SUCCESS_ADD_SEARCH',
};

const GET_JOB = {
  LOAD: 'LOAD_GET_JOB',
  SUCCESS: 'SUCCESS_GET_JOB',
  FAIL: 'FAIL_GET_JOB',
};

const UPDATE_JOB = {
  LOAD: 'LOAD_UPDATE_JOB',
  SUCCESS: 'SUCCESS_UPDATE_JOB',
  FAIL: 'FAIL_UPDATE_JOB',
};

const ADD_COMMENT = {
  LOAD: 'LOAD_ADD_COMMENT',
  SUCCESS: 'SUCCESS_ADD_COMMENT',
  FAIL: 'FAIL_ADD_COMMENT',
};

const DELETE_COMMENT = {
  LOAD: 'LOAD_DELETE_COMMENT',
  SUCCESS: 'SUCCESS_DELETE_COMMENT',
  FAIL: 'FAIL_DELETE_COMMENT',
};

const GET_COMMENT = {
  LOAD: 'LOAD_GET_COMMENT',
  SUCCESS: 'SUCCESS_GET_COMMENT',
  FAIL: 'FAIL_GET_COMMENT',
};

const SEARCH_LOT = {
  LOAD: 'LOAD_SEARCH_LOT',
  SUCCESS: 'SUCCESS_SEARCH_LOT',
  FAIL: 'FAIL_SEARCH_LOT',
};

const SEARCH_JOB = {
  LOAD: 'LOAD_SEARCH_JOB',
  SUCCESS: 'SUCCESS_SEARCH_JOB',
  FAIL: 'FAIL_SEARCH_JOB',
};

export {
  LOGIN,
  FORGOT_PASSWORD,
  ADD_USER,
  USER_LIST,
  UPDATE_USER,
  DELETE_USER,
  LOT_LIST,
  DELETE_LOT,
  CLIENT_LIST,
  UPLOAD_FILE,
  ADD_LOT,
  GET_LOT,
  GET_LOT_FILE,
  UPDATE_LOT,
  DELETE_LOT_FILE,
  GET_MONTHLY_PROGRESS,
  JOB_LIST,
  DELETE_JOB,
  DELETE_JOB_FILE,
  RESET_PASSWORD,
  GET_CLIENT_MONTHLY_PROGRESS,
  ADD_JOB,
  GET_JOB,
  UPDATE_JOB,
  ADD_COMMENT,
  DELETE_COMMENT,
  GET_COMMENT,
  SEARCH_LOT,
  ADD_SEARCH,
  SEARCH_JOB,
};
