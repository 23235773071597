import React, { Fragment, useEffect, useRef, useState } from 'react';
import CommonButton from '../components/Button';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import Dropdown from '../components/Dropdown';
import Textbox from '../components/Textbox';
import { fetchLotList } from '../actions/lotActions/lotList';
import { fetchLotFile } from '../actions/lotActions/getLotFile';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { FileDrop } from 'react-file-drop';

import close from '../assets/images/close.svg';
import closeWhite from '../assets/images/close-white.svg';
import calenderIcon from '../assets/images/icon/calenderIcon.svg';
import pinIcon from '../assets/images/icon/pinIcon.svg';
import fileIcon from '../assets/images/icon/fileIcon.svg';
import commentIcon from '../assets/images/icon/commentIcon.svg';
import {
  getErrorMessage,
  getFileExtention,
  getFileName,
  getFileSize,
  userType,
} from '../utils/appUtils';
import { useSnackbar } from 'notistack';
import CommonSpinner from '../components/CommonSpinner';
import { getJobType } from '../utils/constants';
import moment from 'moment';
import { Strings } from '../utils/Strings';
import { uploadFile } from '../actions/superAdminActions/uploadFile';
import { deleteJobFile } from '../actions/jobActions/deleteJobFile';
import AddComment from '../components/AddComment';
import { createJob } from '../actions/jobActions/addJob';
import { fetchJob } from '../actions/jobActions/getJob';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { updateJob } from '../actions/jobActions/updateJob';
import { Avatar } from '@mui/material';
import { createComment } from '../actions/commentActions/addComment';
import jwtDecode from 'jwt-decode';
import { deleteComment } from '../actions/commentActions/deleteComment';
import { fetchComment } from '../actions/commentActions/getComment';
import caretBlack from '../assets/images/icon/caretBlack.svg';
import Dialog from '../components/Dialog';
// ============================================

const AddJobs = (props) => {
  const inputFile = useRef(null);
  const imageFile = useRef(null);
  const [lotType, setLotType] = useState(0);
  const [title, setTitle] = useState('');
  const [lotTitle, setLotTitle] = useState('');
  const [dueDate, setDueDate] = useState(); //useState(new Date());
  const [createdDate, setCreatedDate] = useState();
  const [fileData, setFileData] = useState([]);
  const [jobFileData, setJobFileData] = useState([]);
  const [onDropEffect, setOnDropEffect] = useState(false);
  const [lotListData, setLotListData] = useState([]);
  const [lotFileData, setLotFileData] = useState([]);
  const [jobStatus, setJobStatus] = useState('To do');
  const [details, setDetails] = useState('');
  const [jobCreatedName, setJobCreatedName] = useState('');
  const [commentArray, setCommentArray] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [jobStatusArray, setJobStatusArray] = useState([]);
  const [comment, setComment] = useState('');
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [getSingleFileData, setGetSingleFileData] = useState();
  const [commentImageData, setCommentImageData] = useState([]);
  const [imageLoad, setImageLoad] = useState(false);
  const [images, setImages] = useState([]);

  const mainUserType = userType();
  const mainUserId = jwtDecode(localStorage.jwtToken).userId;

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    if (props.title === 'Edit job') {
      props.fetchJob(props.jobId, (err) => onError(err));
    }
  }, []);

  useEffect(() => {
    if (props.title === 'Edit job') {
      const data = props.getJobData ? props.getJobData : null;
      if (data) {
        setTitle(data.title);
        setDetails(data.details);
        setFileData(data.attachments);
        setDueDate(new Date(data.dueDate));
        setCreatedDate(new Date(data.createdAt));
        setJobStatus(data.jobStatus);
        setLotType(data.lotId);
        setJobStatusArray(getJobType(data.jobStatus));
        setJobCreatedName(data.createdBy.name);
        setCommentArray(data.comments);
        setLotTitle(data.lotTitle);
      }
    }
  }, [props.getJobData]);

  useEffect(() => {
    if (mainUserType !== 'client' && props.title !== 'Edit job')
      props.fetchLotList((err) => onError(err));
  }, [props.deleteLotData, props.addLotData, props.updateLotData]);

  useEffect(() => {
    if (lotType !== 0) {
      props.fetchLotFile(lotType, (err) => onError(err));
    }
  }, [lotType]);

  useEffect(() => {
    const data = props.getLotFileData ? props.getLotFileData : null;
    if (data) {
      setLotFileData(data);
    }
    if (lotType === 0) {
      setLotFileData([]);
    }
  }, [props.getLotFileData]);

  useEffect(() => {
    if (mainUserType !== 'client' && props.title !== 'Edit job') {
      const data = props.lotListData ? props.lotListData : [];
      const newData = data.map((item) => ({
        title: item.title,
        value: item._id,
      }));
      setLotListData(newData);
    }
  }, [props.lotListData]);

  // ===== File Upload =====
  const onSelectFile = () => {
    inputFile.current.click();
  };
  const onSelectFile1 = () => {
    imageFile.current.click();
  };

  const onFileUpload = (res) => {
    if (res) {
      setFileData([...fileData, ...res]);
      if (props.title === 'Edit job') setJobFileData([...jobFileData, ...res]);
    }
  };

  const handleJobFileUpload = async (files, input) => {
    let newFiles;
    if (input === 'input') {
      newFiles = files.target.files;
    } else {
      newFiles = files;
    }
    const data = {
      files: newFiles,
    };
    props.uploadFile(
      data,
      (res) => onFileUpload(res),
      (err) => onError(err)
    );
    setOnDropEffect(false);
  };

  // === Image File ===
  useEffect(() => {
    setImages(commentImageData);
  }, [commentImageData]);
  const onImageFileUpload = (res) => {
    if (res) {
      setImageLoad(false);
      setCommentImageData([...commentImageData, ...res]);
    }
  };
  const handleCommentImageUpload = async (files, input) => {
    let newFiles;
    if (input === 'input') {
      newFiles = files.target.files;
    } else {
      newFiles = files;
    }
    const data = {
      files: newFiles,
    };
    setImageLoad(true);
    props.uploadFile(
      data,
      (res) => onImageFileUpload(res),
      (err) => onError(err)
    );
  };
  const deleteImageFile = (file) => {
    const findImageObj = commentImageData.filter((e) => e.url !== file);
    setCommentImageData(findImageObj);
  };

  // === Delete Job File ===
  const onDeleteFileResponse = (res, fileUrl) => {
    if (res.data.status === 200) {
      const newFiles = fileData.filter((fileData) => fileData.url !== fileUrl);
      setFileData(newFiles);
      setDeleteDialog(false);
      if (props.title === 'Edit job') {
        const newFiles = jobFileData.filter(
          (fileData) => fileData.url !== fileUrl
        );
        setJobFileData(newFiles);
      }
    }
  };

  const onDeleteFile = (file) => {
    if (file) {
      let tempFile = { url: file.url };
      if (file._id) {
        tempFile.id = file._id;
      }
      const data = {
        file: tempFile,
      };
      if (props.jobId) data.jobId = props.jobId;
      props.deleteJobFile(
        data,
        (res) => onDeleteFileResponse(res, file.url),
        (err) => onError(err)
      );
    }
  };

  const addJobDaisbled = () => {
    if (!title || !lotType || !dueDate || !jobStatus) return true;
    return false;
  };

  const addJob = () => {
    const data = {
      title,
      jobStatus,
      dueDate: new Date(dueDate).toISOString(),
      lotId: lotType,
    };
    if (details) data.details = details;
    if (fileData.length > 0) data.attachments = fileData;
    props.createJob(data, (err) => onError(err), props.onClose);
  };

  const editJob = () => {
    const data = {
      title,
      jobStatus,
      dueDate: new Date(dueDate).toISOString(),
    };
    if (details) data.details = details;
    if (jobFileData.length > 0) data.attachments = jobFileData;
    props.updateJob(props.jobId, data, (err) => onError(err), props.onClose);
  };

  const onAddComment = (res) => {
    if (res.data.status === 200) {
      setComment('');
      setCommentArray([...commentArray, { ...res.data.data }]);
      setCommentImageData([]);
      setImages([]);
    }
  };

  const addComment = () => {
    const data = {
      jobId: props.jobId,
      comment,
      images,
    };
    if (comment || images.length > 0)
      props.createComment(
        data,
        (res) => onAddComment(res),
        (err) => onError(err)
      );
  };

  const successGetComment = (res) => {
    if (res.data.status === 200) {
      setCommentArray(res.data.data.comments);
    }
  };

  const successDeleteComment = (res) => {
    if (res.data.status === 200) {
      props.fetchComment(
        props.jobId,
        (res) => successGetComment(res),
        (err) => onError(err)
      );
    }
  };

  const onDeleteComment = (id) => {
    props.deleteComment(
      props.jobId,
      id,
      (res) => successDeleteComment(res),
      (err) => onError(err)
    );
  };

  useEffect(() => {
    const objDiv = document.getElementById('chatWrapper');
    if (objDiv && objDiv.scrollHeight)
      objDiv.scrollTop =
        (objDiv && objDiv.scrollHeight && objDiv.scrollHeight) || 0;
  }, [commentArray]);

  // === File Download ===
  const fileDownload = (url) => {
    window.open(url);
  };

  return (
    <Fragment>
      <div className='dialog_back_effect' style={{ zIndex: 2 }} />
      <div className='job_dialog_container'>
        {props.lotListLoading || props.getJobLoading ? (
          <CommonSpinner color='white' />
        ) : (
          <>
            {deleteDialog && (
              <Dialog
                title={Strings.FILE_TITLE}
                message={Strings.DELETE_FILE_MESSAGE}
                onSuccess={() =>
                  onDeleteFile(getSingleFileData && getSingleFileData)
                }
                close={() => setDeleteDialog(false)}
                loading={props.deleteJobFileLoading}
              />
            )}
            <div className='job_dialog_inner_container'>
              <div className='job_dialog_main_body'>
                {/* ===== Header ===== */}
                <div className='job_dialog_header_container w-100'>
                  <div className='d-flex flex-column flex-lg-row justify-content-lg-between'>
                    <div
                      className='d-flex justify-content-between'
                      style={{ width: '46%', padding: '0px 20px' }}
                    >
                      <div className='d-flex align-items-center'>
                        <div
                          className='d-flex align-items-center'
                          style={{
                            cursor:
                              props.getJobData &&
                              props.getJobData.jobStatus !== 'Complete' &&
                              props.title !== 'Add job'
                                ? 'pointer'
                                : 'auto',
                          }}
                          onClick={
                            props.getJobData &&
                            props.getJobData.jobStatus !== 'Complete' &&
                            props.title !== 'Add job' &&
                            mainUserType !== 'client' &&
                            handleClick
                          }
                        >
                          <span
                            className={
                              jobStatus === 'In Progress'
                                ? 'dots bg_prim1'
                                : jobStatus === 'Complete'
                                ? 'dots bg_green'
                                : 'dots bg_sec3'
                            }
                          />
                          <span className='font_light font16'>
                            {jobStatus}
                            {props.title === 'Edit job' &&
                            props?.getJobData?.jobStatus !== 'Complete' &&
                            mainUserType !== 'client' ? (
                              <img
                                src={caretBlack}
                                alt=''
                                className={
                                  open ? 'dropdown_arrow ms-2' : 'ms-2'
                                }
                              />
                            ) : null}
                          </span>
                        </div>
                        {props.getJobData &&
                          props.getJobData.jobStatus &&
                          props.getJobData.jobStatus !== 'Complete' &&
                          props.title !== 'Add job' && (
                            <Menu
                              id='basic-menu'
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleClose}
                              MenuListProps={{
                                'aria-labelledby': 'basic-button',
                              }}
                              value={jobStatus}
                            >
                              {jobStatusArray.map((item, index) => (
                                <MenuItem
                                  key={`status${index}`}
                                  value={item.value}
                                  onClick={() => {
                                    handleClose();
                                    setJobStatus(item.value);
                                  }}
                                >
                                  {item.title}
                                </MenuItem>
                              ))}
                            </Menu>
                          )}
                      </div>
                      {/* <div className='d-flex align-items-center'>
                      <img
                        src={DotsThree}
                        alt='menu'
                        className='user_profile'
                      />
                    </div> */}
                    </div>
                    <div
                      className='d-flex flex-column flex-md-row mt-3 mt-lg-0 justify-content-md-between'
                      style={{ width: '54%', padding: '0px 20px' }}
                    >
                      {/* === Created Date === */}
                      {props.title === 'Edit job' ? (
                        <div className='d-flex flex-column'>
                          <span className='font600 font12 font_secondary2'>
                            {Strings.TEXT_CREATED_ON.toUpperCase()}
                          </span>
                          <div className='mt-1 d-flex align-items-center position-relative'>
                            <img
                              src={calenderIcon}
                              alt='date :'
                              style={{
                                height: '22px',
                                width: '22px',
                              }}
                            />
                            <span className='font400 font_secondary2 ms-2'>
                              {moment(createdDate).format('DD MMM, YYYY')}
                            </span>
                          </div>
                        </div>
                      ) : null}
                      {/* === Due Date === */}
                      <div className='d-flex flex-column'>
                        <span className='font600 font12 font_secondary2'>
                          {Strings.TEXT_DUE_TO.toUpperCase()}
                        </span>
                        <div className='mt-1 d-flex align-items-center position-relative'>
                          <img
                            src={calenderIcon}
                            alt='date :'
                            style={{
                              cursor: 'pointer',
                              position: 'absolute',
                              zIndex: 2,
                              height: '22px',
                              width: '22px',
                            }}
                            onClick={() =>
                              document.getElementById('due_date').focus()
                            }
                          />
                          <DatePicker
                            id='due_date'
                            name='due_date'
                            selected={dueDate}
                            onChange={(date) =>
                              mainUserType !== 'client' && setDueDate(date)
                            }
                            dateFormat='dd MMM, yyyy hh:mm:ss a'
                            className='due_datePicker'
                            minDate={moment().toDate()}
                            disabled={mainUserType === 'client'}
                          />

                          <span className='font400 font_secondary2 ms-2'>
                            {dueDate ? (
                              moment(dueDate).format('DD MMM, YYYY')
                            ) : (
                              <span className='font_secondary3'>
                                {Strings.SELECT_DATE}
                              </span>
                            )}
                          </span>
                        </div>
                      </div>

                      <div style={{ width: '145px' }} className='mt-3 mt-md-0'>
                        {mainUserType !== 'client' && (
                          <CommonButton
                            text={
                              props.title !== 'Edit job'
                                ? 'ADD JOB'
                                : 'EDIT JOB'
                            }
                            noMarginTop
                            disabled={addJobDaisbled()}
                            onClick={
                              props.title === 'Edit job' ? editJob : addJob
                            }
                            loading={
                              props.title !== 'Edit job'
                                ? props.addJobLoading
                                : props.updateJobLoading
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* ===== Main Container ===== */}
                <div className='add_jobs_main_container w-100 h-100'>
                  <div className='d-flex flex-column flex-lg-row justify-content-between align-items-between h-100'>
                    {/* ====== add_jobs_details_section ====== */}
                    <div
                      className='d-flex flex-column justify-content-between h-100'
                      style={{
                        width: '46%',
                        overflow: 'hidden',
                      }}
                    >
                      <div
                        style={{
                          height: '100%',
                          overflow: 'auto',
                          padding: '10px 20px 0px 20px',
                        }}
                      >
                        <Textbox
                          noLabel
                          type='text'
                          className='font28 font_light job_title_input'
                          placeholder={Strings.TYPE_TITLE}
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                          disabled={mainUserType === 'client'}
                        />
                        {props.title !== 'Edit job' ? (
                          <Dropdown
                            noLabel
                            title='Select the lot'
                            className='job_dropDown mb-0 font12'
                            value={lotType}
                            onChange={(e) => {
                              setLotType(e.target.value);
                            }}
                            optionArray={lotListData}
                          />
                        ) : (
                          <span className='btn_job aml_box font12'>
                            {lotTitle}
                          </span>
                        )}
                        <Form.Group className='mt-3'>
                          <Form.Label className='font700 font12 font_secondary2'>
                            {Strings.TITLE_DETAILS.toUpperCase()}
                          </Form.Label>
                          <Form.Control
                            as='textarea'
                            placeholder={Strings.TYPE_DETAIL}
                            className='job_Details font400 font16 font_secondary2'
                            value={details}
                            onChange={(e) => setDetails(e.target.value)}
                            disabled={mainUserType === 'client'}
                          />
                        </Form.Group>
                        <div
                          className='d-flex align-items-center'
                          style={{ marginTop: '15px', marginBottom: '15px' }}
                        >
                          <span className='font700 font12 font_secondary2'>
                            {Strings.TITLE_LOT_FILE.toUpperCase()}
                          </span>
                          <span className='font700 font12 font_secondary3 ms-3'>
                            {lotFileData && lotFileData?.length}
                          </span>
                        </div>
                        {/* ==== lots attachment file ==== */}
                        <div>
                          {lotFileData &&
                            lotFileData.length > 0 &&
                            lotFileData.map((data, index) => {
                              const exactSize = getFileSize(data.size);
                              const fileName = getFileName(data.fileName);
                              const extension = getFileExtention(data.fileName);
                              return (
                                <div
                                  className='d-flex justify-content-between mb-2'
                                  key={data._id}
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => fileDownload(data.url)}
                                >
                                  <div className='d-flex align-items-center job_file_name_container'>
                                    <img
                                      src={fileIcon}
                                      alt={data.type}
                                      className='me-2'
                                    />
                                    <div
                                      className='d-flex flex-column'
                                      style={{ marginLeft: 10, width: '95%' }}
                                    >
                                      <span className='font_light font_secondary2 d-flex'>
                                        <span
                                          className='d-inline-block mb-1 file_name'
                                          style={{
                                            marginLeft: 0,
                                            maxWidth: '75%',
                                          }}
                                        >
                                          {fileName}
                                        </span>
                                        <span>.{extension}</span>
                                      </span>
                                      <span className='font12 font600 font_secondary3'>
                                        {exactSize}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                        <div
                          className='d-flex align-items-center'
                          style={{ marginTop: '15px', marginBottom: '15px' }}
                        >
                          <span className='font700 font12 font_secondary2'>
                            {Strings.JOB_FILE_TITLE.toUpperCase()}
                          </span>
                          <span className='font700 font12 font_secondary3 ms-3'>
                            {fileData && fileData.length}
                          </span>
                        </div>
                        {/* ==== jobs attachment file ==== */}
                        <div style={{ paddingBottom: 5 }}>
                          {fileData &&
                            fileData.length > 0 &&
                            fileData.map((data, index) => {
                              const exactSize = getFileSize(data.size);
                              const fileName = getFileName(data.fileName);
                              const extension = getFileExtention(data.fileName);
                              return (
                                <div
                                  className='d-flex justify-content-between mb-2'
                                  key={index}
                                >
                                  <div
                                    className='d-flex align-items-center job_file_name_container'
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => fileDownload(data.url)}
                                  >
                                    <img
                                      src={fileIcon}
                                      alt={data.type}
                                      className='me-2'
                                    />
                                    <div
                                      className='d-flex flex-column'
                                      style={{ marginLeft: 10, width: '95%' }}
                                    >
                                      <span className='font_light font_secondary2 d-flex'>
                                        <span
                                          className='d-inline-block mb-1 file_name'
                                          style={{
                                            marginLeft: 0,
                                            maxWidth: '75%',
                                          }}
                                        >
                                          {fileName}
                                        </span>
                                        <span>.{extension}</span>
                                      </span>
                                      <span className='font12 font600 font_secondary3'>
                                        {exactSize}
                                      </span>
                                    </div>
                                  </div>
                                  {mainUserType !== 'client' && (
                                    <img
                                      src={close}
                                      alt='cancel'
                                      className='delete_file_icon'
                                      onClick={() => {
                                        !props.deleteJobFileLoading &&
                                          setGetSingleFileData(data);
                                        setDeleteDialog(true);
                                      }}
                                    />
                                  )}
                                </div>
                              );
                            })}
                        </div>
                      </div>
                      {/* ===== File Drop ===== */}
                      {mainUserType !== 'client' && (
                        <div style={{ padding: '0px 20px 10px 20px' }}>
                          <FileDrop
                            onDragOver={(event) =>
                              event &&
                              !props.uploadFileLoading &&
                              setOnDropEffect(true)
                            }
                            onDragLeave={(event) =>
                              event &&
                              !props.uploadFileLoading &&
                              setOnDropEffect(false)
                            }
                            onDrop={(files) =>
                              !props.uploadFileLoading &&
                              handleJobFileUpload(files)
                            }
                          >
                            {onDropEffect ? (
                              <div
                                className='d-flex align-items-center justify-content-center'
                                style={{
                                  height: '150px',
                                  width: '100%',
                                  border: '1px dashed #ece5d6',
                                  marginTop: '15px',
                                }}
                              >
                                <span className='font16 font_light'>
                                  Drag the files here
                                </span>
                              </div>
                            ) : props.uploadFileLoading && !imageLoad ? (
                              <div
                                className='d-flex flex-column align-items-center w-100'
                                style={{
                                  border: '1px dashed transparent',
                                  margin: '15px 0',
                                }}
                              >
                                <CommonSpinner color='#2c659b' />
                                <span className='font_primary1 font14 font700'>
                                  Uploading...
                                </span>
                              </div>
                            ) : (
                              <div
                                className='d-flex align-items-center'
                                style={{
                                  border: '1px dashed transparent',
                                  margin: '15px 0',
                                }}
                              >
                                <img
                                  src={pinIcon}
                                  alt='Select File :'
                                  style={{
                                    height: '20px',
                                    width: '20px',
                                    marginRight: '10px',
                                  }}
                                />
                                <input
                                  style={{ display: 'none' }}
                                  ref={inputFile}
                                  onChange={(event) =>
                                    handleJobFileUpload(event, 'input')
                                  }
                                  type='file'
                                  accept='/*'
                                  multiple
                                />
                                <span className='font16 font_light'>
                                  Drop files here to attach or
                                  <span
                                    style={{ cursor: 'pointer' }}
                                    className='font_primary1'
                                    onClick={onSelectFile}
                                  >
                                    &nbsp;browse
                                  </span>
                                </span>
                              </div>
                            )}
                          </FileDrop>
                        </div>
                      )}
                    </div>
                    {/* ===== add_jobs_comment_section ===== */}
                    {props.title !== 'Add job' && (
                      <div
                        className='d-flex flex-column justify-content-between h-100 position-relative'
                        style={{
                          zIndex: 2,
                          width: '54%',
                          overflow: 'hidden',
                        }}
                      >
                        <div
                          className='mt-4 mt-lg-0'
                          style={{ padding: '25px 30px 0px 30px' }}
                        >
                          <div className='d-flex flex-column'>
                            <span className='font700 font12 font_secondary2'>
                              {Strings.TEXT_TIMELINE.toUpperCase()}
                            </span>
                            <span
                              className='font400 font_secondary2'
                              style={{ marginTop: '11px' }}
                            >
                              {Strings.TEXT_JOB_CREATE}
                            </span>
                            <div className='d-flex align-items-center mt-2'>
                              <Avatar
                                sx={{
                                  height: '35px',
                                  width: '35px',
                                  bgcolor: '#2c659b',
                                }}
                              >
                                {jobCreatedName
                                  ? jobCreatedName[0].toUpperCase()
                                  : null}
                              </Avatar>
                              <div className='d-flex flex-column ms-2'>
                                <span className='font400 font_secondary2 font16'>
                                  by {jobCreatedName ? jobCreatedName : ''}
                                </span>
                                <span className='font600 font_secondary3 font12'>
                                  on{' '}
                                  {createdDate
                                    ? moment(createdDate).format(
                                        'DD MMM, YYYY - h:mma'
                                      )
                                    : ''}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            height: '100%',
                            overflow: 'auto',
                            padding: '0px 30px',
                            paddingBottom: 2,
                            marginTop: 10,
                            marginBottom: 20,
                          }}
                          id='chatWrapper'
                        >
                          {/* ===== Add comment message ===== */}
                          {commentArray.map((data, index) => {
                            return (
                              <AddComment
                                key={index}
                                date={data?.createdAt}
                                jobCreatedName={data?.authorId?.name}
                                message={data.comment}
                                data={data}
                                mainUserType={mainUserType}
                                mainUserId={mainUserId}
                                onDeleteComment={(id) => onDeleteComment(id)}
                              />
                            );
                          })}
                        </div>
                        {/* ===== add_comment ===== */}
                        <div
                          className='d-flex align-items-center add_comment'
                          style={{
                            paddingBottom: '15px',
                            flexDirection: 'column',
                          }}
                        >
                          <div
                            style={{
                              maxHeight: 200,
                              overflow: 'auto',
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              {commentImageData && commentImageData.length > 0
                                ? commentImageData.map((data, index) => {
                                    const exactSize = getFileSize(data.size);
                                    const fileName = getFileName(data.fileName);
                                    const extension = getFileExtention(
                                      data.fileName
                                    );
                                    return (
                                      <div key={index}>
                                        <div
                                          className='d-flex justify-content-between mb-2'
                                          style={{ width: 300 }}
                                        >
                                          <div
                                            className='d-flex align-items-center job_file_name_container'
                                            style={{ cursor: 'pointer' }}
                                            onClick={() =>
                                              fileDownload(data.url)
                                            }
                                          >
                                            <img
                                              src={fileIcon}
                                              alt={data.type}
                                              className='me-2'
                                            />
                                            <div
                                              className='d-flex flex-column'
                                              style={{
                                                marginLeft: 10,
                                                width: '95%',
                                              }}
                                            >
                                              <span className='font_light font_secondary2 d-flex'>
                                                <span
                                                  className='d-inline-block mb-1 file_name'
                                                  style={{
                                                    marginLeft: 0,
                                                    maxWidth: '65%',
                                                  }}
                                                >
                                                  {fileName}
                                                </span>
                                                <span>.{extension}</span>
                                              </span>
                                              <span className='font12 font600 font_secondary3'>
                                                {exactSize}
                                              </span>
                                            </div>
                                          </div>
                                          <img
                                            src={close}
                                            alt='cancel'
                                            className='delete_file_icon'
                                            onClick={() =>
                                              deleteImageFile(data.url)
                                            }
                                          />
                                        </div>
                                      </div>
                                    );
                                  })
                                : null}
                            </div>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              width: '100%',
                              alignItems: 'center',
                            }}
                          >
                            <Textbox
                              noLabel
                              placeholder={Strings.ADD_COMMENT_TEXT}
                              className='add_comment_input w-100 mb-0'
                              onChange={(e) => setComment(e.target.value)}
                              value={comment}
                            />
                            {mainUserType === 'client' ? (
                              <FileDrop>
                                {props.uploadFileLoading && imageLoad ? (
                                  <div style={{ marginRight: 10 }}>
                                    <CommonSpinner size='sm' color='#b1ada2' />
                                  </div>
                                ) : (
                                  <>
                                    <img
                                      src={pinIcon}
                                      alt='Select File :'
                                      style={{
                                        height: '20px',
                                        width: '20px',
                                        marginRight: '15px',
                                        cursor: 'pointer',
                                      }}
                                      onClick={onSelectFile1}
                                    />
                                    <input
                                      style={{ display: 'none' }}
                                      ref={imageFile}
                                      onChange={(event) =>
                                        handleCommentImageUpload(event, 'input')
                                      }
                                      type='file'
                                      accept='image/*'
                                      multiple
                                    />
                                  </>
                                )}
                              </FileDrop>
                            ) : null}
                            <CommonButton
                              icon={commentIcon}
                              noMarginTop
                              className='btn_comment'
                              onClick={addComment}
                              loading={props.addCommentLoading}
                              spinColor={'#bbbbbc'}
                              disabled={
                                props.addCommentLoading ||
                                props.uploadFileLoading ||
                                imageLoad
                              }
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* ===== add_job_comment_section_sideColor */}
                  <div className='comment_section_left_sideColor d-none d-lg-block' />
                </div>
              </div>
              {/* ===== Close Icon ===== */}
              <img
                className='job_dialog_close_icon'
                src={closeWhite}
                alt=''
                onClick={props.onClose}
              />
            </div>
          </>
        )}
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  lotListData: state.lotListData.data,
  lotListLoading: state.lotListData.loading,

  getLotFileData: state.getLotFileData.data,
  getLotFileLoading: state.getLotFileData.loading,

  uploadFileLoading: state.uploadFileData.loading,
  deleteJobFileLoading: state.deleteJobFileData.loading,

  addJobLoading: state.addJobData.loading,

  getJobData: state.getJobData.data,
  getJobLoading: state.getJobData.loading,
  updateJobLoading: state.updateJobData.loading,

  addCommentLoading: state.addCommentData.loading,
});

export default connect(mapStateToProps, {
  fetchLotList,
  fetchLotFile,
  uploadFile,
  deleteJobFile,
  createJob,
  fetchJob,
  updateJob,
  createComment,
  deleteComment,
  fetchComment,
})(AddJobs);
