import React, { useState } from 'react';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import CaretDown from '../assets/images/icon/CaretDown.svg';
import profile from '../assets/images/profile.svg';
import store from '../store';
import { logout } from '../actions/authActions/login';
import { useNavigate } from 'react-router-dom';
import Dialog from '../components/Dialog';
import { Strings } from '../utils/Strings';

const MenuButton = (props) => {
  return (
    <Button className='btn_popover transition' onClick={props.onClick}>
      {props.text}
    </Button>
  );
};

const PopoverMenu = (navigate, setOpen, setLogoutDialog) => {
  return (
    <Popover id='popover-basic'>
      <Popover.Header className='d-flex flex-column'>
        <span className='font_light font14'>
          {localStorage.getItem('userName')}
        </span>
        <span className='font_light font14'>
          {localStorage.getItem('email')}
        </span>
      </Popover.Header>
      <Popover.Body>
        <div className='d-flex flex-column'>
          <MenuButton
            text='Home'
            onClick={() => {
              setOpen(false);
              navigate('/');
            }}
          />
          <MenuButton
            text='Logout'
            onClick={() => {
              setOpen(false);
              setLogoutDialog(true);
            }}
          />
        </div>
      </Popover.Body>
    </Popover>
  );
};

const AccountPopover = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [logoutDialog, setLogoutDialog] = useState(false);
  return (
    <React.Fragment>
      {open && (
        <div
          className='dialog_back_effect'
          style={{ backgroundColor: 'transparent' }}
          onClick={() => setOpen(false)}
        />
      )}
      <div className='profile_img'>
        <img
          style={{ borderRadius: '50%', width: 48, height: 48 }}
          src={
            localStorage.getItem('profilePicture')
              ? localStorage.getItem('profilePicture')
              : profile
          }
          alt='no found.'
          // className='w-100'
        />
      </div>
      <OverlayTrigger
        show={open}
        trigger='click'
        placement='bottom'
        overlay={PopoverMenu(navigate, setOpen, setLogoutDialog)}
      >
        <Button className='btn_caretDown' onClick={() => setOpen(!open)}>
          <img src={CaretDown} alt='no found.' />
        </Button>
      </OverlayTrigger>
      {logoutDialog && (
        <Dialog
          title={Strings.LOGOUT_TITLE}
          message={Strings.LOGOUT_MESSAGE}
          onSuccess={() => store.dispatch(logout(navigate))}
          close={() => setLogoutDialog(false)}
        />
      )}
    </React.Fragment>
  );
};

export default AccountPopover;
