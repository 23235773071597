import React from 'react'
import { Col, Row } from 'react-bootstrap'
import CommonButton from '../components/Button'
import Logo from '../assets/images/logo1.png'
import { useNavigate } from 'react-router-dom'
import { Strings } from '../utils/Strings'

const NotFound = () => {
    const navigate = useNavigate()
    const goHome = () => {
        navigate('/')
    }
    return (
        <div className='not_found_container'>
            <div className='not_found_body'>
                <Row className='w-100 flex-column text-center align-items-center justify-content-center'>
                    <Col md={8} lg={5} className='d-flex justify-content-center'>
                        <div className='notFound-page-logo'>
                            <img src={Logo} alt="River Landing" className='w-100 h-100' />
                        </div>
                    </Col>
                    <Col md={8} lg={5} className='text-center'>
                        <span className='font700 font_secondary2 font_404'>
                            4<span className='font_primary1'>0</span>4
                        </span>
                    </Col>
                    <Col md={8} lg={5}>
                        <Row>
                            <Col sm={12} className='mb-3'>
                                <span className='font_primary1 font700 font_notFound'>{Strings.TEXT_PAGE_NOTFOUND}</span>
                            </Col>
                            <Col sm={12}>
                            <span className='font_secondary2 font400' style={{ fontSize:'17px' }}>
                                {Strings.TEXT_PAGE_NOTFOUND_EXTRA}
                            </span>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={8} lg={5} className='text-center'>
                        <CommonButton text="Go To Home" className='btn_notFound' onClick={goHome} />
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default NotFound