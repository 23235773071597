import React from 'react';
import { Form } from 'react-bootstrap';

export default function Textbox(props) {
  const { label, onChange, value, noLabel, disabled } = props;
  return (
    <Form.Group style={{ width: '100%' }}>
      {noLabel ? null : (
        <Form.Label htmlFor={label} className={`font16 font_light form_label`}>
          {label}
        </Form.Label>
      )}
      <Form.Control
        type={props.type ? props.type : 'text'}
        name={props.name}
        className={`${props.error && 'error-input'} ${
          !props.error && 'mb16'
        } form_input ${props.className}`}
        placeholder={props.placeholder}
        onChange={onChange}
        value={value}
        disabled={disabled}
      />
      {props.error && (
        <div className='input_error_text' style={{ marginBottom: 1 }}>
          {props.error}
        </div>
      )}
    </Form.Group>
  );
}
