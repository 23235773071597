import React, { useState, useEffect } from 'react';
// Material Ui
import {
  Avatar,
  Box,
  Checkbox,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
// Table Component
import EnhancedTableHead from '../components/Datatable/TableHead';
// import EnhancedTableToolbar from '../components/Datatable/TableToolbar';
// Icons
import jobsIcon from '../assets/images/icon/jobsIcon.svg';
import lotsIcon from '../assets/images/icon/lotsIcon.svg';
import deleteIcon from '../assets/images/icon/deleteIcon.svg';
import editIcon from '../assets/images/icon/editIcon.svg';
// Common Button
import CommonButton from '../components/Button/index';
// import CommonCheckbox from '../components/Checkbox';
import { connect } from 'react-redux';
import { fetchUserList } from '../actions/superAdminActions/userList';
import { getErrorMessage } from '../utils/appUtils';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import CommonSpinner from '../components/CommonSpinner';
import AddUser from './AddUser';
import Dialog from '../components/Dialog';
import { deleteUser } from '../actions/superAdminActions/deleteUser';
import { Strings } from '../utils/Strings';

// ======== Table Head =========
const headCells = [
  { id: 'name', label: 'Name' },
  { id: 'userType', label: 'Type' },
  { id: 'jobAssigned', label: 'Jobs assigned' },
  { id: 'lotAssigned', label: 'Lots assigned' },
  { id: 'status', label: 'Status' },
];

const UsersPage = (props) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  // const [selected, setSelected] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [userListData, setUserListData] = useState([]);
  const [updateUserDialog, setUpdateUserDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [deleteDialog, setDeleteDialog] = useState(false);

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    props.fetchUserList((err) => onError(err));
  }, [props.addUserData, props.updateUserData, props.deleteUserData]);

  useEffect(() => {
    const data = props.userListData ? props.userListData : [];
    setUserListData(data);
  }, [props.userListData]);

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const onDeleteUser = () => {
    props.deleteUser(selectedUser._id, setDeleteDialog, (err) => onError(err));
  };

  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelecteds = userData.map((n) => n.userName);
  //     setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  // };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }

  //   setSelected(newSelected);
  // };

  // const isSelected = (name) => selected.indexOf(name) !== -1;
  return (
    <Box
      sx={{
        width: '100%',
        height: 'calc(100vh - 78px)',
        overflow: 'auto',
        padding: '0px 24px',
      }}
      className='dataTable_box'
    >
      <Paper sx={{ width: '100%', mb: 2 }} className='dataTable_box'>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        {props.userListLoading ? (
          <Box className='spinner-container'>
            <CommonSpinner color='#2c659b' />
          </Box>
        ) : (
          <>
            {userListData.length === 0 ? (
              <Box className='spinner-container'>
                <p className='no-data-message'>No users found!</p>
              </Box>
            ) : (
              <TableContainer className='dataTable'>
                <Table sx={{ minWidth: 930 }} aria-labelledby='tableTitle'>
                  <EnhancedTableHead
                    headCells={headCells}
                    // numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    // onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={userListData.length}
                  />
                  <TableBody>
                    {stableSort(
                      userListData,
                      getComparator(order, orderBy)
                    ).map((data, index) => {
                      // const isItemSelected = isSelected(data.name);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          // role='checkbox'
                          // aria-checked={isItemSelected}
                          // tabIndex={-1}
                          key={data._id}
                          sx={{ width: '100%' }}
                        >
                          {/* <TableCell padding='none'>
                        <CommonCheckbox
                          onChange={(event) =>
                            handleClick(event, data.name)
                          }
                          checked={isItemSelected}
                          selected={isItemSelected}
                          className='table_checkbox'
                        />
                      </TableCell> */}
                          <TableCell
                            component='td'
                            id={labelId}
                            scope='row'
                            padding='none'
                          >
                            {/* <Box> */}
                            <Stack
                              spacing={2}
                              direction='row'
                              alignItems='center'
                            >
                              <Avatar sx={{ bgcolor: '#2c659b' }}>
                                {data.name[0].toUpperCase()}
                              </Avatar>
                              {/* <Avatar alt={data.name[0].toUpperCase()} src={data.image} /> */}
                              <Stack direction='column' alignItems='flex-start'>
                                <Typography className='font22 font_light'>
                                  {data.name}
                                </Typography>
                                <Typography className='font_secondary3 font_light font16'>
                                  Member since{' '}
                                  {moment(data.createdAt).format('D MMM, YYYY')}
                                </Typography>
                              </Stack>
                            </Stack>
                            {/* </Box> */}
                          </TableCell>
                          <TableCell className='font_light font16'>
                            {data.userType.charAt(0).toUpperCase() +
                              data.userType.slice(1)}
                          </TableCell>
                          <TableCell className='font_light font16'>
                            <img
                              alt='job'
                              src={jobsIcon}
                              style={{ marginRight: '6px' }}
                            />
                            {data.jobAssigned}
                          </TableCell>
                          <TableCell className='font_light font16'>
                            <img
                              alt='job'
                              src={lotsIcon}
                              style={{ marginRight: '6px' }}
                            />
                            {data.lotAssigned}
                          </TableCell>
                          <TableCell>
                            <span className='status_view d-inline-block font700 font16 text-center'>
                              {data.status ? 'Active' : 'InActive'}
                            </span>
                          </TableCell>
                          <TableCell
                            align='right'
                            className='font_light font16'
                          >
                            <Stack
                              direction='row'
                              alignItems='center'
                              justifyContent='end'
                            >
                              <CommonButton
                                noMarginTop
                                className='btn_dataTable'
                                icon={deleteIcon}
                                onClick={() => {
                                  setSelectedUser(data);
                                  setDeleteDialog(true);
                                }}
                              />
                              <CommonButton
                                noMarginTop
                                className='btn_dataTable ms-3'
                                icon={editIcon}
                                onClick={() => {
                                  setSelectedUser(data);
                                  setUpdateUserDialog(true);
                                }}
                              />
                            </Stack>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </>
        )}
      </Paper>
      {updateUserDialog && (
        <AddUser
          title={Strings.EDIT_USER_TITLE}
          onClose={() => {
            setUpdateUserDialog(false);
          }}
          data={selectedUser}
        />
      )}
      {deleteDialog && (
        <Dialog
          title={Strings.USER_TITLE}
          message={Strings.DELETE_USER_MESSAGE}
          onSuccess={onDeleteUser}
          close={() => setDeleteDialog(false)}
          loading={props.deleteUserDataLoading}
        />
      )}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  userListData: state.userListData.data,
  userListLoading: state.userListData.loading,

  addUserData: state.addUserData.data,
  updateUserData: state.updateUserData.data,

  deleteUserData: state.deleteUserData.data,
  deleteUserDataLoading: state.deleteUserData.loading,
});
export default connect(mapStateToProps, {
  fetchUserList,
  deleteUser,
})(UsersPage);
