import React from 'react';
import { Form } from 'react-bootstrap';

export default function CommonCheckbox(props) {
  return (
    <Form.Check
      type={`${!props.type ? 'checkbox' : props.type}`}
      className={`form_check ${props.className}`}
      label={props.label}
      onChange={props.onChange}
      checked={props.checked}
    />
  );
}
