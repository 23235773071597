import { DELETE_JOB } from '../../constants';

const initialState = {
    data: [],
    loading: false,
    error: {},
};

const deleteJobReducer = (state = initialState, { type, payload } = {}) => {
    switch (type) {
        case DELETE_JOB.LOAD:
            return {
                ...state,
                loading: true,
            };
        case DELETE_JOB.SUCCESS:
            return {
                ...state,
                data: payload,
                loading: false,
            };
        case DELETE_JOB.FAIL:
            return {
                ...state,
                error: payload,
                loading: false,
            };
        default:
            return state;
    }
};

export default deleteJobReducer
