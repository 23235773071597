import axios from 'axios';
import { ADD_LOT } from '../../constants';

export const createLot = (data, onError, onClose) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(addLot());
  axios
    .post(`lots/createLots`, data, config)
    .then((res) => {
      dispatch(setLot(res));
      onClose();
    })
    .catch((err) => {
      dispatch(errorLot(err.response));
      onError(err.response);
    });
};

export const addLot = () => ({
  type: ADD_LOT.LOAD,
});

export const setLot = (data) => ({
  type: ADD_LOT.SUCCESS,
  payload: data.data,
});

export const errorLot = (error) => ({
  type: ADD_LOT.FAIL,
  payload: error,
});
