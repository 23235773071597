import axios from 'axios';
import { DELETE_COMMENT } from '../../constants';

export const deleteComment = (jId, cId, onSuccess, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadDeleteComment());
  axios
    .post(`user/deleteComment?jobId=${jId}&commentId=${cId}`, {}, config)
    .then((res) => {
      onSuccess(res);
      dispatch(setDeleteComment(res));
    })
    .catch((err) => {
      dispatch(errorDeleteComment(err.response));
      onError(err.response);
    });
};

export const loadDeleteComment = () => ({
  type: DELETE_COMMENT.LOAD,
});

export const setDeleteComment = (data) => ({
  type: DELETE_COMMENT.SUCCESS,
  payload: data,
});

export const errorDeleteComment = (error) => ({
  type: DELETE_COMMENT.FAIL,
  payload: error,
});
