import React, { Fragment } from 'react';
import CommonButton from '../Button';

export default function Dialog(props) {
  return (
    <Fragment>
      <div className='dialog_back_effect' />
      <div className='common-dialog-container'>
        <div className='common-dialog-inner-container'>
          <div className='common-dialog-header'>
            <label htmlFor='title' className='common-dialog-title'>
              {props.title}
            </label>
          </div>
          <div className='common-dialog-message'>{props.message}</div>
          <div className='action-container'>
            <CommonButton
              text='NO'
              className='dialog-action-button'
              noCommonButton
              noMarginTop
              onClick={props.close}
            />
            <CommonButton
              text='YES'
              className='dialog-action-button action-right-button'
              noCommonButton
              noMarginTop
              onClick={props.onSuccess}
              loading={props.loading}
              disabled={props.loading}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}
