import React from 'react';
import { Spinner } from 'react-bootstrap';

export default function CommonSpinner(props) {
  return (
    <Spinner
      as='span'
      animation='border'
      size={props.size}
      role='status'
      aria-hidden='true'
      style={{ color: props.color }}
    />
  );
}
