import axios from 'axios';
import { UPDATE_LOT } from '../../constants';

export const updateLot = (data, onError, onClose) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadUpdateLot());
  axios
    .put(`lots/editLot`, data, config)
    .then(async (res) => {
      await onClose();
      await dispatch(setUpdateLot(res));
    })
    .catch((err) => {
      dispatch(errorUpdateLot(err.response));
      onError(err.response);
    });
};

export const loadUpdateLot = () => ({
  type: UPDATE_LOT.LOAD,
});

export const setUpdateLot = (data) => ({
  type: UPDATE_LOT.SUCCESS,
  payload: data.data,
});

export const errorUpdateLot = (error) => ({
  type: UPDATE_LOT.FAIL,
  payload: error,
});
