import axios from "axios";
import { DELETE_JOB } from "../../constants";

export const deleteJob = (jobID, setDeleteDialog, onError) => (dispatch) => {
    const token = localStorage.jwtToken;
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    dispatch(loadDeleteJob())
    axios.delete(`/jobs/deleteJob?jobId=${jobID}`, config)
    .then((res) => {
        dispatch(setDeleteJob(res))
        setDeleteDialog(false)
    })
    .catch((err) => {
        dispatch(failDeleteJob(err.response))
        onError(err.response)
    })
}

export const loadDeleteJob = () => ({
    type: DELETE_JOB.LOAD
})
export const setDeleteJob = (data) => ({
    type: DELETE_JOB.SUCCESS,
    payload: data
})
export const failDeleteJob = (error) => ({
    type: DELETE_JOB.FAIL,
    payload: error
})