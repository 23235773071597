import React from 'react';
import { Button } from 'react-bootstrap';
import CommonSpinner from '../CommonSpinner';
const CommonButton = (props) => {
  return (
    <Button
      className={`${!props.noCommonButton && 'btn_common'} ${
        !props.noMarginTop && 'inputMargin'
      } transition ${props.className}`}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.loading ? (
        <CommonSpinner color={props.spinColor} size='sm' />
      ) : (
        <>
          {props.icon && (
            <img src={props.icon} alt='' className={`${props.iconClassName}`} />
          )}
          {props.text}
        </>
      )}
    </Button>
  );
};
export default CommonButton;
