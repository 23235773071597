import axios from 'axios';
import { GET_MONTHLY_PROGRESS } from '../../constants';

export const fetchMonthlyProgress = (onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadGetMonthlyProgress());

  axios
    .get(`admin/monthlyProgress`, config)
    .then((res) => dispatch(setGetMonthlyProgress(res)))
    .catch((err) => {
      dispatch(errorGetMonthlyProgress(err.response));
      onError(err.response);
    });
};

export const loadGetMonthlyProgress = () => ({
  type: GET_MONTHLY_PROGRESS.LOAD,
});

export const setGetMonthlyProgress = (data) => ({
  type: GET_MONTHLY_PROGRESS.SUCCESS,
  payload: data.data.data,
});

export const errorGetMonthlyProgress = (error) => ({
  type: GET_MONTHLY_PROGRESS.FAIL,
  payload: error,
});
