import axios from 'axios';
import { GET_CLIENT_MONTHLY_PROGRESS } from '../../constants';

export const fetchClientMonthlyProgress = (onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(loadGetClientMonthlyProgress());

  axios
    .get(`user/userProgress`, config)
    .then((res) => dispatch(setGetClientMonthlyProgress(res)))
    .catch((err) => {
      dispatch(errorGetClientMonthlyProgress(err.response));
      onError(err.response);
    });
};

export const loadGetClientMonthlyProgress = () => ({
  type: GET_CLIENT_MONTHLY_PROGRESS.LOAD,
});

export const setGetClientMonthlyProgress = (data) => ({
  type: GET_CLIENT_MONTHLY_PROGRESS.SUCCESS,
  payload: data.data.data,
});

export const errorGetClientMonthlyProgress = (error) => ({
  type: GET_CLIENT_MONTHLY_PROGRESS.FAIL,
  payload: error,
});
