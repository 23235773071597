import React, { useEffect, useState } from 'react';
import { menuList } from '../utils/DahboardMenu';
import logo from '../assets/images/logo1.png';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchClientMonthlyProgress } from '../actions/clientActions/getClientMonthlyProgress';
import { getErrorMessage, userType } from '../utils/appUtils';
import { useSnackbar } from 'notistack';
import SidebarProgressCard from '../components/card/SidebarProgressCard';

const ActivateLink = (props) => {
  return (
    <React.Fragment>
      {true && (
        <NavLink className='menu_item font_light' to={props.to}>
          {props.children}
        </NavLink>
      )}
    </React.Fragment>
  );
};

const SidebarMenu = (props) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [clientMonthlyProgressData, setClientMonthlyProgressData] =
    useState(null);

  const isClient = userType() === 'client';
  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error',
      });
    }
  };
  useEffect(() => {
    if (isClient) props.fetchClientMonthlyProgress((err) => onError(err));
  }, []);

  useEffect(() => {
    if (isClient) {
      const data = props.getClientMonthlyProgressData
        ? props.getClientMonthlyProgressData
        : null;
      setClientMonthlyProgressData(data);
    }
  }, [props.getClientMonthlyProgressData]);
  return (
    <div className='sidebar'>
      {/* ========== Logo ========== */}
      <div className='sidebar-header'>
        <img src={logo} alt='not found...' className='w-100 h-100' />
      </div>

      {/* ========== Menu ========== */}
      <div className='sidebar-body'>
        <div className='menu_box'>
          {menuList().map((data, index) => {
            return (
              <ActivateLink key={index} to={data.path}>
                <img
                  src={data.icon}
                  alt='not found'
                  className='menu_icon'
                  style={{ height: '22px', width: '22px' }}
                />
                {data.title}
              </ActivateLink>
            );
          })}
        </div>
      </div>
      {userType() === 'client' && (
        <SidebarProgressCard
          loading={props.getClientMonthlyProgressLoading}
          data={clientMonthlyProgressData}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  getClientMonthlyProgressData: state.getClientMonthlyProgressData.data,
  getClientMonthlyProgressLoading: state.getClientMonthlyProgressData.loading,
});

export default connect(mapStateToProps, {
  fetchClientMonthlyProgress,
})(SidebarMenu);
