import { DELETE_JOB_FILE } from "../../constants";

const initialState = {
    data: null,
    loading: false,
    error: {}
}

const deleteJobFileReducer = (state= initialState, { type, payload } = {}) => {
    switch (type) {
        case DELETE_JOB_FILE.LOAD:
            return {
                ...state,
                loading: true
            }
        case DELETE_JOB_FILE.SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload
            }
        case DELETE_JOB_FILE.FAIL:
            return {
                ...state,
                loading: false,
                error: payload
            }
        default:
            return state;
    }
}

export default deleteJobFileReducer;