import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import CommonSpinner from '../CommonSpinner';

const progressColor = 'rgba(44, 101, 155, 1)';
const completeColor = 'rgba(98, 217, 160, 1)';

const SidebarProgressCard = (props) => {
  const { loading, data } = props;
  const inProgress =
    (data && data.inProgress && Math.round(data.inProgress)) || 0;
  const complete = (data && data.complete && Math.round(data.complete)) || 0;

  const total = (data && data.total && data.total) || 0;
  return (
    <div style={{ width: '90%', marginTop: 'auto', marginBottom: 10 }}>
      <div className='progress_card'>
        {loading ? (
          <div
            style={{ height: 'calc(100% - 40px)' }}
            className='progress_chart d-flex justify-content-center align-items-center'
          >
            <CommonSpinner color='#58585a' />
          </div>
        ) : (
          <div
            style={{ height: '88%' }}
            className='progress_chart d-flex flex-column justify-content-between align-items-center'
          >
            {/* =========== chart =========== */}
            <div style={{ width: 70, height: 70 }}>
              <CircularProgressbar
                value={complete}
                text={`${total}`}
                styles={buildStyles({
                  strokeLinecap: 'butt',
                  trailColor: completeColor,
                  pathColor: progressColor,
                })}
              />
            </div>
            {/*  =========== Texts ===========  */}
            <div className='d-flex flex-column'>
              <div className='d-flex align-items-center mb10'>
                <div
                  className='dots'
                  style={{ backgroundColor: `${progressColor}` }}
                ></div>
                <span className='font_light font_info'>
                  {inProgress}% in progress
                </span>
              </div>
              <div className='d-flex align-items-center mb10'>
                <div
                  className='dots'
                  style={{ backgroundColor: `${completeColor}` }}
                ></div>
                <span className='font_light font_info'>
                  {complete}% Complete
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SidebarProgressCard;
