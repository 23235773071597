const Strings = {
    
    // Dashboard Text // DashboardMenu.js
    DASHBOARD_TITLE: 'Dashboard',

    // User Text // Navbar // User page // Add user page //dashboard page //DashboardMenu.js
    USER_TITLE: 'Users',
    ADD_USER_TITLE: 'New user',
    EDIT_USER_TITLE: 'Edit user',
    USER_NOTFOUND: 'No user found!',
    DELETE_USER_MESSAGE : 'Are you sure, you want to delete user?',

    // Lots Text // Navbar // Lots Page // Add lots page //dashboard page //DashboardMenu.js
    LOT_TITLE: 'Lots',
    ADD_LOT_TITLE: 'New lot',
    EDIT_LOT_TITLE: 'Edit lot',
    LOT_NOTFOUND: 'No lot found!',
    DELETE_LOT_MESSAGE : 'Are you sure, you want to delete lot?',

    // Jobs Text // Navbar // Jobs page // Add Jobs page //dashboard page //DashboardMenu.js
    JOB_TITLE: 'Jobs',
    JOB_COMPLETE: 'Jobs Complete',
    TEXT_DUE_TO: 'Due To',
    TEXT_CREATED_ON: 'Created On',
    TEXT_JOB_CREATE: 'Job created',
    TITLE_DETAILS: 'Details',
    TEXT_TIMELINE: 'Timeline',
    TITLE_LOT_FILE: 'Files in this lot',
    JOB_FILE_TITLE: 'Job Attachments',
    JOB_NOTFOUND: 'No job found!',
    FILE_NOTFOUND: 'File not found!',
    DELETE_JOB_MESSAGE : 'Are you sure, you want to delete job?',
    TYPE_TITLE: 'Type the title here',
    TYPE_DETAIL: 'Type the details here',
    SELECT_DATE: 'Select the date',
    ADD_COMMENT_TEXT: 'Type here to add your comment',

    // File Text // Add Jobs
    FILE_TITLE: 'File',
    DELETE_FILE_MESSAGE: 'Are you sure, you want to delete file?',

    // Logout // AccountPopover.jsx
    LOGOUT_TITLE: 'Logout',
    LOGOUT_MESSAGE: 'Are you sure, you want to logout?',

    // To do //Jobs Data // Jobs page
    TO_DO_TITLE: 'To do',

    // InProgress //Jobs Data // Jobs page
    IN_PROGRESS_TITLE: 'In Progress',

    // Complete //Jobs Data // Jobs page
    COMPLETE_TITLE: 'Complete',

    // Page Notfound // notfound page
    TEXT_PAGE_NOTFOUND: 'Sorry, page not found!',
    TEXT_PAGE_NOTFOUND_EXTRA: "Sorry, we couldn't find the page you're looking for. Perhaps you've mistyped the URL? Be sure to check your spelling.",

    // Verification text // Add User page // Login page // ResetPassword page
    EMAIL_REQUIRED: 'Email must be required!',
    PASSWORD_REQUIRED: 'Password must be required!',
    VALID_EMAIL: 'Please enter valid email!',
    VALID_PHONE_NO: 'Only 10 digits allow in phone number!',
    VALID_ONLY_NUMBER: 'Only digits allow in phone number!',

    //Reset Password Dialog component
    RESET_PASSWORD_WElCOME_TEXT: 'Welcome onboard',
    VALID_PASSWORD: 'Must Contain One Uppercase, One Lowercase, One Number and one special case Character with minimum 8 character',
    SAME_PASSWORD_TEXT: 'New password and Repeat password should be same!',
    RESET_PASSWORD_EXTRA_TEXT: 'Is your first time here, so you need to update your password',

    // Login page Extra text
    TEXT_WELCOME: 'Welcome back',
    TEXT_ENTER_DETAILS: 'Please enter your details.',
    TEXT_DONT_ACCOUNT: "Don't have an account?",
    TEXT_CONTACT_SUPPORT: 'Contact the support team.',
    TEXT_FORGET_PASSWORD: 'Forget password',
    TEXT_REMEMBER: 'Remember me',

    // Forget Password page extra text
    TEXT_RESET_PASSWORD: 'Reset your password',
    TEXT_ENTER_PASSWORD_DETAILS: 'Please enter your details to receive the link to update your password',

}

export { Strings }