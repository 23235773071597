import axios from 'axios';
import { GET_LOT_FILE } from '../../constants';

export const fetchLotFile = ( lotId, onError ) => ( dispatch ) => {
    const token = localStorage.jwtToken;
    const config = {
        headers: {Authorization: `Bearer ${token}`}
    }
    dispatch(loadGetLotFile());
    axios.get(`/lots/getLotFiles?lotId=${lotId}`, config)
    .then((res) => dispatch(setGetLotFile(res.data.data)))
    .catch((err) => {
        dispatch(errorGetLotFile(err.response))
        onError(err)
    })
}

export const loadGetLotFile = () => ({
    type: GET_LOT_FILE.LOAD
})

export const setGetLotFile = (data) => ({
    type: GET_LOT_FILE.SUCCESS,
    payload: data,
})

export const errorGetLotFile = (error) => ({
    type: GET_LOT_FILE.FAIL,
    payload: error
})