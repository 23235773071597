import { ADD_SEARCH } from '../../constants';

export const setSearchData = (search) => (dispatch) => {
  dispatch(setSearchSuccess(search));
};

export const setSearchSuccess = (data) => ({
  type: ADD_SEARCH.SUCCESS,
  payload: data,
});
