import axios from 'axios';
import { ADD_COMMENT } from '../../constants';

export const createComment = (data, onSuccess, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  dispatch(addComment());
  axios
    .post(`user/createComment`, data, config)
    .then((res) => {
      onSuccess(res);
      dispatch(setComment(res));
    })
    .catch((err) => {
      dispatch(errorComment(err.response));
      onError(err.response);
    });
};

export const addComment = () => ({
  type: ADD_COMMENT.LOAD,
});

export const setComment = (data) => ({
  type: ADD_COMMENT.SUCCESS,
  payload: data.data,
});

export const errorComment = (error) => ({
  type: ADD_COMMENT.FAIL,
  payload: error,
});
